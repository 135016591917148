const initialState = {
  data: null,
  fetching: true
}

export default function dashboard (state = initialState, action) {
  switch (action.type) {
    case "DASHBOARD_FETCHING":
      return {
        ...initialState,
        fetching: true
      }
    case "GET_DASHBOARD":
      return {
        ...state,
        data: action.payload,
        fetching: false
      }
    case "ADD_TOAST":
      return {
        ...state,
        fetching: false
      }
    default:
      return state
  }
}

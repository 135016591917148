import { APIServices } from './APIServices'
import { addToast, dispatchError } from '../store/errorsToast'

export const getDashboard = () => {
  return dispatch => {
    dispatch({ type: "DASHBOARD_FETCHING" })
    APIServices()
      .getDashboard()
      .then(response => {
        const responseData = response.data
        if (response.status === 200) {
          dispatch({
            type: "GET_DASHBOARD",
            payload: responseData
          })
        } else {
          dispatch(dispatchError(responseData))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.error) {
            dispatch(addToast({ error: error.response.data.error }))
          }
          if (error.response.data.message) {
            dispatch(addToast({ error: error.response.data.message }))
          }
        } else {
          dispatch(addToast({ error: error.message }))
        }
      })
  }
}

import React, { useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { getDashboard } from '../api/Dashboard'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Table from '../components/Table'
import moment from 'moment'
import { Link, useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  fixedHeight: {
    height: 140
  },
  seeMore: {
    marginTop: theme.spacing(3),
    '& a': {
      textDecoration: 'none',
      color: '#3f51b5'
    }
  }
}))

const mapStateToProps = ({
  dashboard: { data, fetching },
}) => {
  return {
    data,
    fetching
  }
}
const mapDispatchToProps = (dispatch) => ({
  dispatchGetDashboard: () => {
    dispatch(getDashboard())
  }
})

const Dashboard = ({
  data,
  fetching,
  dispatchGetDashboard
}) => {
  const classes = useStyles()
  const columns = [
    { title: "IP Address", field: "ipAddress" },
    { title: "Country", field: "country" },
    { title: "City", field: "city" },
    { title: "User Agent", field: "userAgent" },
    {
      title: "Number of tries",
      render: (rowData) => { return rowData.sessions.length }
    },
    {
      title: "Date/Time",
      field: "createdAt",
      render: (rowData) => { return moment(rowData.createdAt).format('D MMM YYYY HH:mm') }
    }
  ]
  const history = useHistory()
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)

  useEffect(() => {
    dispatchGetDashboard()
  }, [])

  return (
    <>
      {data &&
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={4}>
            <Paper className={fixedHeightPaper}>
              <Typography component="h2" variant="h5" color="primary" gutterBottom>
                Guests this day
              </Typography>
              <Typography component="p" variant="h4">
                {data.todayGuests}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper className={fixedHeightPaper}>
              <Typography component="h2" variant="h5" color="primary" gutterBottom>
                Guests Last 7 days
              </Typography>
              <Typography component="p" variant="h4">
                {data.lastWeekGuests}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper className={fixedHeightPaper}>
              <Typography component="h2" variant="h5" color="primary" gutterBottom>
                Guests Last 30 days
              </Typography>
              <Typography component="p" variant="h4">
                {data.lastMonthGuests}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography component="h2" variant="h5" color="primary" gutterBottom>
                Last 10 Guests
              </Typography>
              <Table
                allowDelete={false}
                allowEdit={true}
                allowAdd={false}
                style={{ maxWidth: "100%" }}
                columns={columns}
                rowsPerPage={data.lastGuests.length}
                data={data.lastGuests}
                isLoading={fetching}
                pageNumber={1}
                totalData={data.lastGuests.length}
                onRowUpdate={(item) => {
                  history.push("/panel/guests/" + item.id)
                }}
                updateTooltip="View"
                title="Guests"
              />
              <div className={classes.seeMore}>
                <Link color="primary" to="/panel/guests" >
                  View all guests
                </Link>
              </div>
            </Paper>
          </Grid>
        </Grid>
      }
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)

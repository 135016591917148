const initialState = {
  list: [],
}

export default function errorsForm (state = initialState, action) {
  switch (action.type) {
    case 'SET_FORM_ERRORS':
      return {
        ...state,
        list: action.payload.list
      }
    default:
      return state
  }
}

import React, { useEffect, Fragment, useState } from 'react'
import { connect } from 'react-redux'
import FormElement from '../../components/FormElement'
import DialogForm from '../../components/DialogForm'
import { getCharacter, updateCharacter } from '../../api/Character'
import { config } from '../../config'

const mapStateToProps = ({
  character: { fetching: fetchingCharacter, character, submitting }
}) => {
  return { character, submitting, fetchingCharacter }
}

const mapDispatchToProps = dispatch => ({
  submitUpdateItem: (characterId, item) => {
    dispatch(updateCharacter(characterId, item))
  },
  dispatchGetItem: characterId => {
    dispatch(getCharacter(characterId))
  }
})

const Create = ({
  characterId,
  open,
  handleClose,
  submitUpdateItem,
  dispatchGetItem,
  submitting,
  fetchingCharacter,
  character,
  initialValues
}) => {
  const [uploadedCharacterPicture, setUploadedCharacterPicture] = useState(null)
  const [uploadedProfilePicture, setUploadedProfilePicture] = useState(null)
  const validationSchema = {
    fullName: {
      presence: { allowEmpty: false },
      length: {
        maximum: 150
      }
    },
    username: {
      presence: { allowEmpty: false },
      length: {
        maximum: 150
      }
    },
    colorHEX: {
      presence: { allowEmpty: false },
    },
    profession: {
      presence: { allowEmpty: false },
    },
    bio: {
      presence: { allowEmpty: false },
    },
    finalText: {
      presence: { allowEmpty: false },
    }
  }

  useEffect(() => {
    if (characterId) dispatchGetItem(characterId)
  }, [])

  const handleCharacterPictureChange = (file) => {
    setUploadedCharacterPicture(file)
  }

  const handleProfilePictureChange = (file) => {
    setUploadedProfilePicture(file)
  }

  return (
    <Fragment>
      <DialogForm
        open={open}
        handleClose={handleClose}
        title='Edit Character'
        onSubmit={item => {
          const data = new FormData()
          Object.keys(item)
            .forEach((key) => {
              if (key !== 'profilePicture' && key !== 'characterPicture') {
                data.append(key, item[key])
              }
            })
          if (uploadedCharacterPicture) {
            data.append('characterPicture', uploadedCharacterPicture)
          }
          if (uploadedProfilePicture) {
            data.append('profilePicture', uploadedProfilePicture)
          }
          data.append('_method', 'PUT')
          submitUpdateItem(characterId, data)
        }}
        submitting={submitting}
        submitLabel='Update'
        validationSchema={validationSchema}
        fetchingData={characterId && fetchingCharacter}
        initialValues={
          initialValues
            ? initialValues
            : characterId && character
            ? {
              fullName: character.fullName,
              username: character.username,
              colorHEX: character.colorHEX,
              profession: character.profession,
              bio: character.bio,
              finalText: character.finalText,
            }
            : {
              fullName: "",
              username: "",
              colorHEX: "",
              profession: "",
              bio: "",
              finalText: "",
            }
        }
      >
        <FormElement elementType="text" label="Full Name*" name="fullName" />
        <FormElement elementType="text" label="Username*" name="username" />
        <FormElement elementType="text" label="Color HEX*" name="colorHEX" />
        <FormElement elementType="text" label="Profession*" name="profession" />
        <FormElement
          elementType="text"
          label="Bio*"
          name="bio"
          multiline={true}
          rows={5}
        />
        <FormElement
          elementType="text"
          label="The final text*"
          name="finalText"
          multiline={true}
          rows={3}
        />
        {character && character.characterPicture &&
        <img src={config.FILE_URL + character.characterPicture} width="200" />
        }
        <FormElement elementType="file" label="Character Picture" name="characterPicture" onFileChange={handleCharacterPictureChange} />
        {character && character.profilePicture &&
          <img src={config.FILE_URL + character.profilePicture} width="200" />
        }
        <FormElement elementType="file" label="Profile Picture" name="profilePicture" onFileChange={handleProfilePictureChange} />
      </DialogForm>
    </Fragment>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Create)

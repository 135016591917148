import React, { forwardRef, useRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography
} from '@material-ui/core'
import CloseIcon from '../assets/icons/CloseIcon'
import Form from './Form'
import PromptConfirmation from './PromptConfirmation'

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})

const MuiDialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose
        ? <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon width={16} color={'black'} />
          </IconButton>
        : null}
    </DialogTitle>
  )
})

const DialogForm = forwardRef(
  ({ open, handleClose, title, ...formProps }, ref) => {
    const dialogRef = useRef()

    const checkIfTouched = event => {
      const form = ref || dialogRef
      if (form && form.current && form.current.checkIfTouched()) {
        PromptConfirmation({
          message: 'You have unsaved changes are you sure you want to quit?',
          callback: confirmed => {
            if (confirmed) handleClose(event)
            else return undefined
          }
        })
      } else handleClose(event)
    }

    return (
      <Dialog
        onClose={checkIfTouched}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <MuiDialogTitle id="customized-dialog-title" onClose={checkIfTouched}>
          {title}
        </MuiDialogTitle>
        <DialogContent>
          <Form
            ref={ref || dialogRef}
            {...formProps}
            showCancelButton={Boolean(handleClose)}
            cancelLabel="Cancel"
            onCancelButtonClick={checkIfTouched}
          ></Form>
        </DialogContent>
      </Dialog>
    )
  }
)

DialogForm.displayName = 'DialogForm'

export default DialogForm

import { APIServices } from './APIServices'
import { addToast, dispatchError } from '../store/errorsToast'

export const getAllCharacters = params => {
  return dispatch => {
    dispatch({ type: "ALL_CHARACTERS_FETCHING" })
    APIServices()
      .getAllCharacters(params)
      .then(response => {
        const responseData = response.data
        if (response.status === 200) {
          dispatch({
            type: "GET_ALL_CHARACTERS",
            payload: responseData
          })
        } else {
          dispatch(dispatchError(responseData))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.error) {
            dispatch(addToast({ error: error.response.data.error }))
          }
          if (error.response.data.message) {
            dispatch(addToast({ error: error.response.data.message }))
          }
        } else {
          dispatch(addToast({ error: error.message }))
        }
      })
  }
}

export const getCharacter = itemId => {
  return dispatch => {
    dispatch({ type: "CHARACTER_FETCHING" })
    APIServices()
      .getCharacter(itemId)
      .then(response => {
        const responseData = response.data
        if (response.status === 200) {
          dispatch({
            type: "GET_CHARACTER",
            payload: {
              character: responseData
            }
          })
        } else {
          dispatch(dispatchError(responseData))
        }
      })
      .catch(error => {
        console.log("error", error)
      })
  }
}

export const updateCharacter = (itemId, item) => {
  return dispatch => {
    if (item) {
      dispatch({ type: "CHARACTER_SUBMIT_FORM_ACTION" })
      APIServices()
        .updateCharacter(itemId, item)
        .then(response => {
          const responseData = response.data
          if (response.status === 200) {
            dispatch({
              type: "TOGGLE_CHARACTER_UPDATE",
              payload: {
                showUpdateModal: false
              }
            })
          } else {
            dispatch(dispatchError(responseData))
          }
        })
        .catch(error => {
          dispatch({ type: "CHARACTER_SUBMIT_FORM_ACTION" })
          dispatch(dispatchError(error.response))
        })
    }
  }
}

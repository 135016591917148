import React from 'react'
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

const TableFilters = ({ filters, handleFiltersChange, data }) => {
  return (
    <>
    <div style={{ width: '30%', display: 'inline-block' }}>
      {filters["characterId"] !== undefined && (
        <FormControl
          variant="outlined"
          fullWidth
          margin="normal"
        >
          <InputLabel shrink htmlFor="media-type-search">
            Character
          </InputLabel>
          <Select
            label="Character"
            id="media-type-search"
            value={filters['characterId'] || ''}
            onChange={event => {
              const value = event.target.value
              if (value) {
                const newFilters = {
                  ...filters,
                  characterId: value
                }
                handleFiltersChange(newFilters)
              } else {
                const newFilters = {
                  ...filters,
                  characterId: ''
                }
                handleFiltersChange(newFilters)
              }
            }}
            IconComponent={props => (
              <KeyboardArrowDownIcon width={16} height={16} {...props} />
            )}
            displayEmpty
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {data.characters.map((value, key) => {
              return (
                <MenuItem className={"select-menu-item"} value={value.id} key={key}>
                  {value.fullName}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      )}
    </div>
    <div style={{ width: '30%', display: 'inline-block', marginLeft: '10px' }}>
      {filters["categoryId"] !== undefined && (
        <FormControl
          variant="outlined"
          fullWidth
          margin="normal"
        >
          <InputLabel shrink htmlFor="media-type-search">
            Badge
          </InputLabel>
          <Select
            label="Badge"
            id="media-type-search"
            value={filters['categoryId'] || ''}
            onChange={event => {
              const value = event.target.value
              if (value) {
                const newFilters = {
                  ...filters,
                  categoryId: value
                }
                handleFiltersChange(newFilters)
              } else {
                const newFilters = {
                  ...filters,
                  categoryId: ''
                }
                handleFiltersChange(newFilters)
              }
            }}
            IconComponent={props => (
              <KeyboardArrowDownIcon width={16} height={16} {...props} />
            )}
            displayEmpty
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {data.categories.map((value, key) => {
              return (
                <MenuItem className={"select-menu-item"} value={value.id} key={key}>
                  {value.name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      )}
    </div>
  </>
  )
}

export default TableFilters

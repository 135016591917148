const initialState = {
  list: [],
  totalData: 0,
  fetching: true,
  showSessionPostsModal: false
}

export default function sessionPosts (state = initialState, action) {
  switch (action.type) {
    case "ALL_SESSION_POSTS_FETCHING":
      return {
        ...initialState,
        fetching: true,
        showSessionPostsModal: state.showSessionPostsModal
      }
    case "GET_ALL_SESSION_POSTS":
      return {
        ...state,
        list: action.payload.data,
        totalData: action.payload.meta.total,
        fetching: false
      }
    case "TOGGLE_SESSION_POSTS_MODAL":
      return {
        ...state,
        showSessionPostsModal: action.payload.showSessionPostsModal
      }
    case "ADD_TOAST":
      return {
        ...state,
        fetching: false
      }
    case 'CLEAR_SESSION_POSTS':
      return {
        ...initialState,
        fetching: false
      }
    default:
      return state
  }
}

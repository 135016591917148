import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Table from '../../components/Table'
import CreateUpdate from './CreateUpdate'
import * as _ from 'lodash'
import { getAllCharacters } from '../../api/Character'

const mapStateToProps = ({
   character: { showUpdateModal },
   characters: { list, totalData, fetching },
}) => {
  return {
    list,
    totalData,
    fetching,
    showUpdateModal
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchGetAll: (pageNumber) => {
    dispatch(getAllCharacters({ page: pageNumber }))
  },
  toggleUpdateModal: (showUpdateModal) => {
    dispatch({
      type: 'TOGGLE_CHARACTER_UPDATE',
      payload: {
        showUpdateModal: showUpdateModal,
      },
    })
  }
})

const List = ({
  list,
  totalData,
  fetching,
  dispatchGetAll,
  showUpdateModal,
  toggleUpdateModal
}) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [selectedItemId, setSelectedItemId] = useState(null)

  const columns = [
    { title: "Full Name", field: "fullName" },
    { title: "Profession", field: "profession" },
    {
      title: "Bio",
      field: "bio",
      render: (rowData) => {
        return _.truncate(rowData.bio, {
          length: 100,
          separator: /,?\.* +/,
        })
      },
    }
  ]

  useEffect(() => {
    if (!showUpdateModal) {
      dispatchGetAll(pageNumber)
    }
  }, [showUpdateModal])

  return (
    <>
      {showUpdateModal && (
        <CreateUpdate
          open={showUpdateModal}
          handleClose={() => toggleUpdateModal(false)}
          characterId={selectedItemId}
        />
      )}

      <Table
        allowDelete={false}
        allowEdit={true}
        allowAdd={false}
        style={{ maxWidth: "100%" }}
        columns={columns}
        rowsPerPage={10}
        data={list}
        isLoading={fetching}
        pageNumber={pageNumber}
        onChangePage={(newPage) => {
          setPageNumber(newPage)
          dispatchGetAll(newPage)
        }}
        totalData={totalData}
        onRowUpdate={(item) => {
          setSelectedItemId(item.id)
          toggleUpdateModal(true)
        }}
        addTooltip="Add Character"
        title="Characters"
      />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(List)

import React, { useEffect, Fragment, useState } from 'react'
import { connect } from 'react-redux'
import FormElement from '../../components/FormElement'
import DialogForm from '../../components/DialogForm'
import { getCategory, updateCategory } from '../../api/Category'
import { config } from '../../config'

const mapStateToProps = ({
  category: { fetching: fetchingCategory, category, submitting }
}) => {
  return { category, submitting, fetchingCategory }
}

const mapDispatchToProps = dispatch => ({
  submitUpdateItem: (categoryId, item) => {
    dispatch(updateCategory(categoryId, item))
  },
  dispatchGetCategory: categoryId => {
    dispatch(getCategory(categoryId))
  }
})

const Create = ({
  categoryId,
  open,
  handleClose,
  submitUpdateItem,
  dispatchGetCategory,
  submitting,
  fetchingCategory,
  category,
  initialValues
}) => {
  const [uploadedIcon, setUploadedIcon] = useState(null)
  const validationSchema = {
    name: {
      presence: { allowEmpty: false },
      length: {
        maximum: 150
      }
    }
  }

  useEffect(() => {
    if (categoryId) dispatchGetCategory(categoryId)
  }, [])

  const handleFileChange = (file) => {
    setUploadedIcon(file)
  }

  return (
    <Fragment>
      <DialogForm
        open={open}
        handleClose={handleClose}
        title='Edit Badge'
        onSubmit={item => {
          const data = new FormData()
          Object.keys(item)
            .forEach((key) => {
              if (key !== 'icon' || key !== 'isActive') {
                data.append(key, item[key])
              }
            })
          if (uploadedIcon) {
            data.append('icon', uploadedIcon)
          }
          data.append('isActive', item.isActive ? 1 : 0)
          data.append('_method', 'PUT')
          submitUpdateItem(categoryId, data)
        }}
        submitting={submitting}
        submitLabel="Update"
        validationSchema={validationSchema}
        fetchingData={categoryId && fetchingCategory}
        initialValues={
          initialValues
            ? initialValues
            : categoryId && category
            ? {
              name: category.name,
              description: category.description || '',
              isActive: category.isActive
            }
            : {
              name: "",
              description: "",
              isActive: false
            }
        }
      >
        <FormElement elementType="text" label="Name*" name="name" />
        {false &&
        <FormElement
          elementType="text"
          label="Description"
          name="description"
          multiline={true}
          rows={3}
        />
        }
        {category && category.icon &&
          <img src={config.FILE_URL + category.icon} width="200" />
        }
        <FormElement elementType="file" label="Icon" name="icon" onFileChange={handleFileChange} />
        {false &&
          <FormElement elementType="switch" label="Active" name="isActive" />
        }
      </DialogForm>
    </Fragment>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Create)

import React, { useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import FormElement from '../../components/FormElement'
import DialogForm from '../../components/DialogForm'
import { getStage, updateStage } from '../../api/Stage'

const mapStateToProps = ({
  stage: { fetching: fetchingStage, stage, submitting }
}) => {
  return { stage, submitting, fetchingStage }
}

const mapDispatchToProps = dispatch => ({
  submitUpdateItem: (stageId, item) => {
    dispatch(updateStage(stageId, item))
  },
  dispatchGetStage: stageId => {
    dispatch(getStage(stageId))
  }
})

const Create = ({
  stageId,
  open,
  handleClose,
  submitUpdateItem,
  dispatchGetStage,
  submitting,
  fetchingStage,
  stage,
  initialValues
}) => {
  const validationSchema = {
    title: {
      presence: { allowEmpty: true },
      length: {
        maximum: 150
      }
    },
  }

  useEffect(() => {
    if (stageId) dispatchGetStage(stageId)
  }, [])

  return (
    <Fragment>
      <DialogForm
        open={open}
        handleClose={handleClose}
        title='Edit Screen'
        onSubmit={item => submitUpdateItem(stageId, item)}
        submitting={submitting}
        submitLabel={"Update"}
        validationSchema={validationSchema}
        fetchingData={stageId && fetchingStage}
        initialValues={
          initialValues
            ? initialValues
            : stageId && stage
            ? {
              stageWord: stage.stageWord,
              stepWord: stage.stepWord,
              title: stage.title || '',
              content: stage.content || '',
              textBeforeBtn: stage.textBeforeBtn || '',
              btnText: stage.btnText || ''
            }
            : {
              stageWord: "",
              stepWord: "",
              title: "",
              content: "",
              textBeforeBtn: "",
              btnText: ""
            }
        }
      >
        <FormElement elementType="text" label="Screen*" name="stageWord" disabled />
        <FormElement elementType="text" label="Step*" name="stepWord" disabled />
        <FormElement elementType="text" label="Title" name="title" />
        <FormElement
          elementType="text"
          label="Content"
          name="content"
          multiline={true}
          rows={5}
        />
        <FormElement elementType="text" label="Text Before the Button" name="textBeforeBtn" />
        <FormElement elementType="text" label="Button Text" name="btnText" />
      </DialogForm>
    </Fragment>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Create)

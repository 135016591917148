import { APIServices } from './APIServices'
import { addToast, dispatchError } from '../store/errorsToast'

export const getAllPosts = params => {
  if (params.categoryId) {
    if (params.categoryId === 'all') {
      delete params.categoryId
    }
  }
  if (params.categoryId === null) {
    delete params.categoryId
  }
  if (params.characterId) {
    if (params.characterId === 'all') {
      delete params.characterId
    }
  }
  if (params.characterId === null) {
    delete params.characterId
  }
  return dispatch => {
    dispatch({ type: "ALL_POSTS_FETCHING" })
    APIServices()
      .getAllPosts(params)
      .then(response => {
        const responseData = response.data
        if (response.status === 200) {
          dispatch({
            type: "GET_ALL_POSTS",
            payload: responseData
          })
        } else {
          dispatch(dispatchError(responseData))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.error) {
            dispatch(addToast({ error: error.response.data.error }))
          }
          if (error.response.data.message) {
            dispatch(addToast({ error: error.response.data.message }))
          }
        } else {
          dispatch(addToast({ error: error.message }))
        }
      })
  }
}

export const getPost = itemId => {
  return dispatch => {
    dispatch({ type: "POST_FETCHING" })
    APIServices()
      .getPost(itemId)
      .then(response => {
        const responseData = response.data
        if (response.status === 200) {
          dispatch({
            type: "GET_POST",
            payload: {
              post: responseData
            }
          })
        } else {
          dispatch(dispatchError(responseData))
        }
      })
      .catch(error => {
        console.log("error", error)
      })
  }
}

export const createPost = item => {
  return dispatch => {
    dispatch({ type: "POST_SUBMIT_FORM_ACTION" })
    APIServices()
      .createPost(item)
      .then(response => {
        const responseData = response.data
        if (response.status === 200) {
          dispatch({
            type: "TOGGLE_POST_ADD",
            payload: {
              showAddModal: false,
              postAdded: responseData
            }
          })
        } else {
          dispatchError(responseData, dispatch)
        }
      })
      .catch(error => {
        dispatch({ type: "POST_SUBMIT_FORM_ACTION" })
        dispatch(dispatchError(error.response))
      })
  }
}

export const updatePost = (itemId, item) => {
  return dispatch => {
    if (item) {
      dispatch({ type: "POST_SUBMIT_FORM_ACTION" })
      APIServices()
        .updatePost(itemId, item)
        .then(response => {
          const responseData = response.data
          if (response.status === 200) {
            dispatch({
              type: "TOGGLE_POST_UPDATE",
              payload: {
                showUpdateModal: false
              }
            })
          } else {
            dispatch(dispatchError(responseData))
          }
        })
        .catch(error => {
          dispatch({ type: "POST_SUBMIT_FORM_ACTION" })
          dispatch(dispatchError(error.response))
        })
    }
  }
}

export const deletePost = itemId => {
  return dispatch => {
    dispatch({ type: "POST_SUBMIT_FORM_ACTION" })
    APIServices()
      .deletePost(itemId)
      .then(response => {
        const responseData = response.data
        if (response.status === 200) {
          dispatch({
            type: "TOGGLE_POST_DELETE",
            payload: {
              showDeleteDialog: false
            }
          })
        } else {
          dispatch(dispatchError(responseData))
        }
      })
      .catch(error => {
        dispatch({ type: "POST_SUBMIT_FORM_ACTION" })
        dispatch(dispatchError(error.response))
      })
  }
}

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Tooltip as MuTooltip } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  tooltip: {
    fontSize: 12
  },
  tooltipPlacementLeft: {
    margin: '0 8px'
  },
  tooltipPlacementRight: {
    margin: '0 8px'
  },
  tooltipPlacementTop: {
    margin: '8px 0'
  },
  tooltipPlacementBottom: {
    margin: '8px 0'
  }
}))

const Tooltip = ({ title, children, ...restProps }) => {
  const classes = useStyles()
  return (
    <MuTooltip classes={classes} title={title} {...restProps}>
      {children}
    </MuTooltip>
  )
}

export default Tooltip

import cookie from 'react-cookies'
import { config } from '../config'
import axios from 'axios'

const axiosInstance = axios.create()
const APIURL = config.API_URL

const mapParams = (params) => {
  const mappedParams = new URLSearchParams()
  Object.keys(params).forEach(function (key) {
    mappedParams.append(key, params[key])
  })
  return mappedParams
}

export const APIServices = () => {
  const auth = 'Bearer ' + cookie.load('access_token')

  return {
    login: credentials => axiosInstance.post(`${APIURL}/auth/admin`, credentials),

    logout: () => {
      return axiosInstance.post(`${APIURL}/auth/admin/logout`, undefined, {
        headers: {
          Authorization: auth
        }
      })
    },

    me: () => axiosInstance.get(`${APIURL}/auth/admin/me`, {
      headers: {
        Authorization: auth
      }
    }),

    // categories
    getAllCategories: params => {
      return axios.get(
        `${APIURL}/categories`,
        {
          headers: { Authorization: auth },
          params: mapParams(params)
        }
      )
    },

    getCategory: itemId => {
      return axios.get(`${APIURL}/categories/${itemId}`, {
        headers: { Authorization: auth }
      })
    },

    updateCategory: (itemId, item) => {
      return axios.post(
        `${APIURL}/categories/${itemId}`,
        item,
        {
          headers: { Authorization: auth, 'content-type': 'multipart/form-data' }
        }
      )
    },

    // characters
    getAllCharacters: params => {
      return axios.get(
        `${APIURL}/characters`,
        {
          headers: { Authorization: auth },
          params: mapParams(params)
        }
      )
    },

    getCharacter: itemId => {
      return axios.get(`${APIURL}/characters/${itemId}`, {
        headers: { Authorization: auth }
      })
    },

    updateCharacter: (itemId, item) => {
      return axios.post(
        `${APIURL}/characters/${itemId}`,
        item,
        {
          headers: { Authorization: auth, 'content-type': 'multipart/form-data' }
        }
      )
    },

    // posts
    getAllPosts: params => {
      return axios.get(
        `${APIURL}/posts`,
        {
          headers: { Authorization: auth },
          params: mapParams(params)
        }
      )
    },

    getPost: itemId => {
      return axios.get(`${APIURL}/posts/${itemId}`, {
        headers: { Authorization: auth }
      })
    },

    createPost: item => {
      return axios.post(
        `${APIURL}/posts`,
        item,
        {
          headers: { Authorization: auth, 'content-type': 'multipart/form-data' }
        }
      )
    },

    updatePost: (itemId, item) => {
      return axios.post(
        `${APIURL}/posts/${itemId}`,
        item,
        {
          headers: { Authorization: auth, 'content-type': 'multipart/form-data' }
        }
      )
    },

    deletePost: itemId => {
      return axios.delete(`${APIURL}/posts/${itemId}`, {
        headers: { Authorization: auth }
      })
    },

    // screens
    getAllStages: params => {
      return axios.get(
        `${APIURL}/screens`,
        {
          headers: { Authorization: auth },
          params: mapParams(params)
        }
      )
    },

    getStage: itemId => {
      return axios.get(`${APIURL}/screens/${itemId}`, {
        headers: { Authorization: auth }
      })
    },

    updateStage: (itemId, item) => {
      return axios.put(
        `${APIURL}/screens/${itemId}`,
        item,
        {
          headers: { Authorization: auth }
        }
      )
    },

    // guests
    getAllGuests: params => {
      return axios.get(
        `${APIURL}/guests`,
        {
          headers: { Authorization: auth },
          params: mapParams(params)
        }
      )
    },

    getGuest: (itemId, params) => {
      return axios.get(`${APIURL}/guests/${itemId}`, {
        headers: { Authorization: auth },
        params: mapParams(params)
      })
    },

    createGuest: (data) => {
      return axios.post(
        `${APIURL}/guests`,
        data,
        {
          headers: { Authorization: auth }
        }
      )
    },

    // sessions
    getAllSessions: params => {
      return axios.get(
        `${APIURL}/sessions`,
        {
          headers: { Authorization: auth },
          params: mapParams(params)
        }
      )
    },

    getSession: itemId => {
      return axios.get(`${APIURL}/sessions/${itemId}`, {
        headers: { Authorization: auth }
      })
    },

    createSession: item => {
      return axios.post(
        `${APIURL}/sessions`,
        item,
        {
          headers: { Authorization: auth }
        }
      )
    },

    // session posts
    getAllSessionPosts: params => {
      return axios.get(
        `${APIURL}/session-posts`,
        {
          headers: { Authorization: auth },
          params: mapParams(params)
        }
      )
    },

    getSessionPost: itemId => {
      return axios.get(`${APIURL}/session-posts/${itemId}`, {
        headers: { Authorization: auth }
      })
    },

    createSessionPost: item => {
      return axios.post(
        `${APIURL}/session-posts`,
        item,
        {
          headers: { Authorization: auth }
        }
      )
    },

    // dashboard
    getDashboard: () => {
      return axios.get(
        `${APIURL}/dashboard`,
        {
          headers: { Authorization: auth }
        }
      )
    }
  }
}

const initialState = {
  post: null,
  fetching: true,
  submitting: false,
  showUpdateModal: false,
  showAddModal: false,
  showDeleteDialog: false
}

export default function post (state = initialState, action) {
  switch (action.type) {
    case 'POST_FETCHING':
      return {
        ...state,
        fetching: true,
      }
    case 'GET_POST':
      return {
        ...state,
        post: action.payload.post,
        fetching: false,
        submitting: false,
      }
    case 'POST_SUBMIT_FORM_ACTION':
      return {
        ...state,
        submitting: true,
      }
    case 'TOGGLE_POST_UPDATE':
      return {
        ...state,
        showUpdateModal: action.payload.showUpdateModal,
        submitting: false,
      }
    case 'TOGGLE_POST_ADD':
      return {
        ...state,
        showAddModal: action.payload.showAddModal,
        postAdded: action.payload.postAdded,
        submitting: false,
      }
    case 'TOGGLE_POST_DELETE':
      return {
        ...state,
        showDeleteDialog: action.payload.showDeleteDialog,
        submitting: false,
      }
    case 'ADD_TOAST':
      return {
        ...state,
        fetching: false,
        submitting: false,
      }
    default:
      return state
  }
}

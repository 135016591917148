import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'

export const MuiMenuItem = React.forwardRef(({ onClick, iconComponent, text }, ref) => (
  <MenuItem onClick={onClick} ref={ref}>
    <ListItemText primary={text} />
  </MenuItem>
))

MuiMenuItem.displayName = 'MuiMenuItem'

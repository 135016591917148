import { combineReducers } from 'redux'
import userProfile from './userProfile'
import errorsForm from './errorsForm'
import errorsToast from './errorsToast'
import category from './category'
import categories from './categories'
import character from './character'
import characters from './characters'
import post from './post'
import posts from './posts'
import stage from './stage'
import stages from './stages'
import guest from './guest'
import guests from './guests'
import session from './session'
import sessions from './sessions'
import sessionPost from './sessionPost'
import sessionPosts from './sessionPosts'
import dashboard from './dashboard'

export default combineReducers({
  category,
  categories,
  character,
  characters,
  post,
  posts,
  stage,
  stages,
  guest,
  guests,
  session,
  sessions,
  sessionPost,
  sessionPosts,
  dashboard,
  userProfile,
  errorsForm,
  errorsToast
})

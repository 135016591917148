import React from 'react'
import './assets/styles/main.scss'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Game from './views/Game'
import Login from './views/Login'
import CategoriesList from './views/Categories/List'
import CharactersList from './views/Characters/List'
import PostsList from './views/Posts/List'
import StagesList from './views/Stages/List'
import GuestsList from './views/Guests/List'
import GuestView from './views/Guests/View'
import Authentication from './components/Authentication'
import PrivateRoute from './components/PrivateRoute'
import {
  ThemeProvider,
  createTheme
} from '@material-ui/core/styles'
import thunk from 'redux-thunk'
import {
  createStore,
  applyMiddleware,
  compose
} from 'redux'
import {
  Provider
} from 'react-redux'
import reducers from './store/reducers'
import Dashboard from './views/Dashboard'
import ErrorsToast from './components/ErrorsToast'
import Helmet from 'react-helmet'
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)))

const theme = createTheme()

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Route exact path='/' component={Game} />
        <ThemeProvider theme={theme}>
          {window.location.hostname === 'game.verify-sy.com' && false &&
          <Helmet
            script={[{
              src: 'https://www.googletagmanager.com/gtag/js?id=G-7328PGZ071',
              async: true,
              type: 'text/javascript'
            }, {
              type: 'text/javascript',
              innerHTML: 'window.dataLayer = window.dataLayer || [];' +
                'function gtag(){dataLayer.push(arguments);}' +
                'gtag("js", new Date());' +
                'gtag("config", "G-7328PGZ071");'
            }
            ]}
          />
          }
          <Route path='/panel/login' component={Login} />
          <Authentication>
            <PrivateRoute path='/panel/dashboard' component={Dashboard} title='Dashboard' />
            <PrivateRoute path='/panel/guests' component={GuestsList} title='Guests' />
            <PrivateRoute path='/panel/guests/:id' component={GuestView} title='View Guest' />
            <PrivateRoute path='/panel/posts' component={PostsList} title='Posts' />
            <PrivateRoute path='/panel/characters' component={CharactersList} title='Characters' />
            <PrivateRoute path='/panel/badges' component={CategoriesList} title='Badges' />
            <PrivateRoute path='/panel/screens' component={StagesList} title='Screens' />
          </Authentication>
        </ThemeProvider>
        <ErrorsToast />
      </Router>

    </Provider>
  )
}

export default App

import React from 'react'
import ReactDOM from 'react-dom'
import DialogConfirmation from './DialogConfirmation'
import { MuiThemeProvider } from '@material-ui/core/styles'

const PromptConfirmation = ({ message, callback }) => {
  const container = document.createElement("div")
  container.setAttribute("custom-confirmation-navigation", "")
  document.body.appendChild(container)

  const closeModal = callbackState => {
    ReactDOM.unmountComponentAtNode(container)
    document.body.removeChild(container)
    callback(callbackState)
  }

  ReactDOM.hydrate(
    <MuiThemeProvider>
      <DialogConfirmation
        open={true}
        handleClose={() => closeModal(false)}
        handleConfirm={() => closeModal(true)}
        title="Warning !"
        message={message}
      />
    </MuiThemeProvider>,
    container
  )
}

export default PromptConfirmation

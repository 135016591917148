import cookie from 'react-cookies'
import { APIServices } from './APIServices'
import { addToast, dispatchError } from '../store/errorsToast'

export const login = credentials => {
  return dispatch => {
    dispatch({ type: 'USER_PROFILE_REQUEST' })
    APIServices()
      .login(credentials)
      .then(response => {
        const responseData = response.data
        if (response.status === 200) {
          cookie.save('access_token', responseData.data.token, {
            path: '/'
          })
          cookie.save('user_id', responseData.data.user.id)
          localStorage.setItem(
            'user_profile',
            JSON.stringify(responseData.data.user)
          )
          dispatch({
            type: 'SET_USER_PROFILE',
            payload: {
              user: responseData.data.user
            }
          })
        } else {
          dispatch(dispatchError(responseData))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.error) {
            dispatch(addToast({ error: error.response.data.error }))
          }
        } else {
          dispatch(addToast({ error: error.message }))
        }
      })
  }
}

export const getUserProfile = (currentLocation) => {
  return dispatch => {
    APIServices()
      .me()
      .then(response => {
        const responseData = response.data
        if (response.status === 200) {
          dispatch({
            type: 'SET_USER_PROFILE',
            payload: {
              user: responseData.data.user
            }
          })
          localStorage.setItem(
            'user_profile',
            JSON.stringify(responseData.data.user)
          )
        } else {
          dispatch(dispatchError(responseData))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401 && currentLocation !== '/panel/login') {
            cookie.remove('access_token', {
              path: '/'
            })
            cookie.remove('user_id', {
              path: '/'
            })
            localStorage.removeItem('user_profile')
            location.href = '/panel/login'
          }
        }
      })
  }
}

export const logout = () => {
  return dispatch => {
    return new Promise((resolve) => {
      dispatch({ type: 'USER_PROFILE_REQUEST' })
      APIServices()
        .logout()
        .then(response => {
          if (response.status === 200) {
            cookie.remove('access_token', {
              path: '/'
            })
            cookie.remove('user_id', {
              path: '/'
            })
            localStorage.removeItem('user_profile')
            dispatch({
              type: 'SET_USER_PROFILE',
              payload: {
                user: null
              }
            })
            return resolve()
          }
        })
        .catch(error => {
          console.log('error', error)
        })
    })
  }
}

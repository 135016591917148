import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Dialog from '../../components/Dialog'
import { getAllSessionPosts } from '../../api/SessionPost'
import Table from '../../components/Table'
import Typography from '@material-ui/core/Typography'

const mapStateToProps = ({
  sessionPosts: { list, totalData, fetching }
}) => {
  return {
    list,
    totalData,
    fetching,
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchGetSessionPosts: (sessionId) => {
    dispatch(getAllSessionPosts({ sessionId: sessionId, paginate: 0 }))
  }
})

const SessionPosts = ({
  open,
  sessionObject,
  sessionId,
  handleClose,
  dispatchGetSessionPosts,
  list,
  totalData,
  fetching,
}) => {
  const columns = [
    {
      title: "Post",
      field: "post.post"
    },
    {
      title: "Correct answer",
      field: "isCorrect",
      render: (rowData) => { return rowData.isCorrect ? 'Yes' : 'No' }
    }
  ]

  useEffect(() => {
    dispatchGetSessionPosts(sessionId)
  }, [])

  return (
    <>
      {sessionObject &&
        <Dialog
          open={open}
          handleClose={handleClose}
          title={sessionObject.character.fullName}
        >
          {list && list.length > 0 ? (
          <Table
            allowDelete={false}
            allowEdit={false}
            allowAdd={false}
            style={{ maxWidth: "100%" }}
            columns={columns}
            rowsPerPage={totalData}
            data={list}
            isLoading={fetching}
            pageNumber={1}
            totalData={totalData}
            title="Posts"
          />
          ) : (
            <Typography component="p" variant="body1">
              No Data
            </Typography>
          )}
        </Dialog>
      }
    </>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(SessionPosts)

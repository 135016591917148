const initialState = {
  sessionPost: null,
  fetching: true,
  submitting: false
}

export default function sessionPost (state = initialState, action) {
  switch (action.type) {
    case 'SESSION_POST_FETCHING':
      return {
        ...state,
        fetching: true,
      }
    case 'GET_SESSION_POST':
      return {
        ...state,
        sessionPost: action.payload.sessionPost,
        fetching: false,
        submitting: false,
      }
    case 'SESSION_POST_SUBMIT_FORM_ACTION':
      return {
        ...state,
        submitting: true,
      }
    case 'TOGGLE_SESSION_POST_ADD':
      return {
        ...state,
        submitting: false,
      }
    case 'ADD_TOAST':
      return {
        ...state,
        fetching: false,
        submitting: false,
      }
    default:
      return state
  }
}

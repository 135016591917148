const initialState = {
  category: null,
  fetching: true,
  submitting: false,
  showUpdateModal: false
}

export default function category (state = initialState, action) {
  switch (action.type) {
    case 'CATEGORY_FETCHING':
      return {
        ...state,
        fetching: true,
      }
    case 'GET_CATEGORY':
      return {
        ...state,
        category: action.payload.category,
        fetching: false,
        submitting: false,
      }
    case 'CATEGORY_SUBMIT_FORM_ACTION':
      return {
        ...state,
        submitting: true,
      }
    case 'TOGGLE_CATEGORY_UPDATE':
      return {
        ...state,
        showUpdateModal: action.payload.showUpdateModal,
        submitting: false,
      }
    case 'ADD_TOAST':
      return {
        ...state,
        fetching: false,
        submitting: false,
      }
    default:
      return state
  }
}

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Table from '../../components/Table'
import CreateUpdate from './CreateUpdate'
import { getAllStages } from '../../api/Stage'

const mapStateToProps = ({
   stage: { showUpdateModal },
   stages: { list, totalData, fetching },
}) => {
  return {
    list,
    totalData,
    fetching,
    showUpdateModal
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchGetAll: (pageNumber) => {
    dispatch(getAllStages({ page: pageNumber }))
  },
  toggleUpdateModal: (showUpdateModal) => {
    dispatch({
      type: 'TOGGLE_STAGE_UPDATE',
      payload: {
        showUpdateModal: showUpdateModal,
      },
    })
  }
})

const List = ({
  list,
  totalData,
  fetching,
  dispatchGetAll,
  showUpdateModal,
  toggleUpdateModal
}) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [selectedItemId, setSelectedItemId] = useState(null)

  const columns = [
    { title: "Screen", field: "stageWord" },
    { title: "Step", field: "stepWord" }
  ]

  useEffect(() => {
    if (!showUpdateModal) {
      dispatchGetAll(pageNumber)
    }
  }, [showUpdateModal])

  return (
    <>
      {showUpdateModal && (
        <CreateUpdate
          open={showUpdateModal}
          handleClose={() => toggleUpdateModal(false)}
          stageId={selectedItemId}
        />
      )}

      <Table
        allowDelete={false}
        allowEdit={true}
        allowAdd={false}
        style={{ maxWidth: "100%" }}
        columns={columns}
        rowsPerPage={10}
        data={list}
        isLoading={fetching}
        pageNumber={pageNumber}
        onChangePage={(newPage) => {
          setPageNumber(newPage)
          dispatchGetAll(newPage)
        }}
        totalData={totalData}
        onRowUpdate={(item) => {
          setSelectedItemId(item.id)
          toggleUpdateModal(true)
        }}
        addTooltip="Add screen"
        title="Screens"
      />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(List)

const initialState = {
  character: null,
  fetching: true,
  submitting: false,
  showUpdateModal: false
}

export default function character (state = initialState, action) {
  switch (action.type) {
    case 'CHARACTER_FETCHING':
      return {
        ...state,
        fetching: true,
      }
    case 'GET_CHARACTER':
      return {
        ...state,
        character: action.payload.character,
        fetching: false,
        submitting: false,
      }
    case 'CHARACTER_SUBMIT_FORM_ACTION':
      return {
        ...state,
        submitting: true,
      }
    case 'TOGGLE_CHARACTER_UPDATE':
      return {
        ...state,
        showUpdateModal: action.payload.showUpdateModal,
        submitting: false,
      }
    case 'ADD_TOAST':
      return {
        ...state,
        fetching: false,
        submitting: false,
      }
    default:
      return state
  }
}

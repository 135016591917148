import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={70}
    height={70}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#a)">
      <circle cx={35} cy={32} r={31} fill="#F13535" />
      <circle cx={35} cy={32} r={30.5} stroke="#fff" />
    </g>
    <path d="M23 43.5 47 21M47 43.5 23 21" stroke="#fff" strokeWidth={5} />
    <defs>
      <filter
        id="a"
        x={0}
        y={0}
        width={70}
        height={70}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_241_7632"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_241_7632"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)

export default SvgComponent

import React from 'react'

const SVG = ({ color = '#c0c0c', ...restProps }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18.202" height="18.203" viewBox="0 0 18.202 18.203" {...restProps}>
    <rect fill={color} width="3.028" height="22.713" transform="translate(16.06 0) rotate(44.998)"/>
    <rect fill={color} width="3.028" height="22.713" transform="translate(18.202 16.061) rotate(135.002)"/>
  </svg>
)

export default SVG

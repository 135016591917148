import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Table from '../../components/Table'
import { getAllGuests } from '../../api/Guest'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

const mapStateToProps = ({
   guests: { list, totalData, fetching },
}) => {
  return {
    list,
    totalData,
    fetching
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchGetAll: (pageNumber) => {
    dispatch(getAllGuests({ page: pageNumber, 'scopes[]': 'getSessions' }))
  }
})

const List = ({
  list,
  totalData,
  fetching,
  dispatchGetAll
}) => {
  const history = useHistory()
  const [pageNumber, setPageNumber] = useState(1)

  const columns = [
    { title: "IP Address", field: "ipAddress" },
    { title: "Country", field: "country" },
    { title: "City", field: "city" },
    { title: "User Agent", field: "userAgent" },
    {
      title: "Number of tries",
      render: (rowData) => { return rowData.sessions.length }
    },
    {
      title: "Date/Time",
      field: "createdAt",
      render: (rowData) => { return moment(rowData.createdAt).format('D MMM YYYY HH:mm') }
    }
  ]

  useEffect(() => {
    dispatchGetAll(pageNumber)
  }, [])

  return (
    <>
      <Table
        allowDelete={false}
        allowEdit={true}
        allowAdd={false}
        style={{ maxWidth: "100%" }}
        columns={columns}
        rowsPerPage={10}
        data={list}
        isLoading={fetching}
        pageNumber={pageNumber}
        onChangePage={(newPage) => {
          setPageNumber(newPage)
          dispatchGetAll(newPage)
        }}
        totalData={totalData}
        onRowUpdate={(item) => {
          history.push("/panel/guests/" + item.id)
        }}
        updateTooltip="View"
        title="Guests"
      />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(List)

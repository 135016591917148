import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={70}
    height={70}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#a)">
      <circle cx={35} cy={32} r={31} fill="#20FB1B" />
      <circle cx={35} cy={32} r={30.5} stroke="#fff" />
    </g>
    <path
      d="m21.722 32.188-1.813-1.722-3.443 3.625 1.812 1.721 3.444-3.624ZM30 43.5l-1.722 1.813 2.213 2.102 1.625-2.584L30 43.5Zm-11.722-7.688 10 9.5 3.444-3.624-10-9.5-3.444 3.624Zm13.838 9.02C40.475 31.541 45 25.497 54.527 23.443l-1.054-4.888c-11.85 2.555-17.367 10.542-25.59 23.613l4.233 2.662Z"
      fill="#fff"
    />
    <defs>
      <filter
        id="a"
        x={0}
        y={0}
        width={70}
        height={70}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_241_7572"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_241_7572"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)

export default SvgComponent

import React, { useState, useEffect, Fragment } from 'react'
import MaterialTable from 'material-table'
import { Box, Button, IconButton } from '@material-ui/core'
import Tooltip from '../components/Tooltip'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import AddIcon from '@material-ui/icons/Add'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import DeleteIcon from '../assets/icons/DeleteIcon'

const Table = ({
  filterable = false,
  searchable = false,
  sortable = false,
  allowEdit = false,
  allowDelete = false,
  allowAdd = false,
  allowEditPermissionAction,
  allowDeletePermissionAction,
  allowPermissionType,
  columns,
  data,
  rowsPerPage,
  pageNumber,
  onChangePage,
  totalData,
  isLoading,
  title,
  onRowUpdate,
  onRowDelete,
  onRowAdd,
  onRowClick,
  selectedRow,
  addTooltip,
  deleteTooltip,
  updateTooltip,
  filters,
  customOperations,
  ...restProps
}) => {
  const [pages, setPages] = useState([])
  const currentPage = pageNumber

  useEffect(() => {
    if (rowsPerPage > 0) {
      setPages(
        Array.from(Array(Math.ceil(totalData / rowsPerPage)), (_, i) => i + 1)
      )
    }
  }, [totalData, rowsPerPage])

  const operations =
    allowEdit || allowDelete || customOperations || allowEditPermissionAction || allowDeletePermissionAction
      ? {
        title: "",
        render: rowData =>
          (customOperations && customOperations(rowData)) || (
            <Box
              display={"flex"}
              style={{ margin: "0 auto" }}
            >
              {(allowEdit) && (
                <Button
                  disableElevation
                  color="primary"
                  variant="outlined"
                  size="small"
                  className={"edit-button"}
                  onClick={() => onRowUpdate(rowData)}
                >
                  {updateTooltip || "Edit"}
                </Button>
              )}
              {(allowDelete) && (
                <Button
                  disableElevation
                  color="primary"
                  size="small"
                  className={"delete-button"}
                  startIcon={<DeleteIcon width={15} color="#3f51b5" />}
                  onClick={() => onRowDelete(rowData.id)}
                >
                  {deleteTooltip || "Delete"}
                </Button>
              )}
            </Box>
          )
      }
      : {}

  return (
    <div className={"table"}>
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        {allowAdd && (
          <Tooltip title={addTooltip} placement="top">
            <Button
              disableElevation
              variant="contained"
              color="primary"
              className={"add-button"}
              onClick={onRowAdd}
              style={{ marginBottom: '10px' }}
            >
              <AddIcon />
            </Button>
          </Tooltip>
        )}
      </Box>
      <MaterialTable
        components={{
          Pagination: props => null
        }}
        options={{
          toolbar: false,
          filtering: filterable,
          search: searchable,
          sorting: sortable,
          pageSize: rowsPerPage,
          rowStyle: rowData => ({
            backgroundColor:
              selectedRow && selectedRow.id === rowData.id ? "#EEE" : "#FFF",
          }),
          draggable: false
        }}
        localization={{
          body: {},
          header: {
            actions: ""
          }
        }}
        columns={Object.keys(operations).length > 0 ? [...columns, operations] : columns}
        data={data}
        isLoading={isLoading}
        onRowClick={onRowClick}
        {...restProps}
      />
      {pages.length > 1 &&
      <div className="pagination">
        <IconButton
          color="primary"
          disabled={currentPage - 1 < 1}
          onClick={() => onChangePage(currentPage - 1)}
        >
          <NavigateNextIcon />
        </IconButton>
        {currentPage > 3 &&
        <Fragment>
          <IconButton
            color="primary"
            className="number"
            onClick={() => onChangePage(1)}
          >
            1
          </IconButton>
          <div className="pagination-seperator">...</div>
        </Fragment>
        }
        {pages.map(page => {
          return (
            page > currentPage - 3 &&
            page < currentPage + 3 && (
              <IconButton
                color="primary"
                className={page === currentPage ? "current-page" : "number"}
                key={page}
                onClick={() => onChangePage(page)}
              >
                {page}
              </IconButton>
            )
          )
        })}
        {currentPage + 3 <= pages.length &&
        <Fragment>
          <div className="pagination-seperator">...</div>
          <IconButton
            color="primary"
            className="number"
            onClick={() => onChangePage(pages.length)}
          >
            {pages.length}
          </IconButton>
        </Fragment>
        }
        <IconButton
          color="primary"
          disabled={currentPage + 1 > pages.length}
          onClick={() => onChangePage(currentPage + 1)}
        >
          <NavigateBeforeIcon />
        </IconButton>
      </div>
      }
    </div>
  )
}

export default Table

const initialState = {
  session: null,
  sessionAdded: null,
  sessionPosts: [],
  sessionCategories: [],
  fetching: true,
  submitting: false
}

export default function session (state = initialState, action) {
  switch (action.type) {
    case 'SESSION_FETCHING':
      return {
        ...state,
        fetching: true,
      }
    case 'GET_SESSION':
      return {
        ...state,
        session: action.payload.session,
        fetching: false,
        submitting: false,
      }
    case 'SESSION_SUBMIT_FORM_ACTION':
      return {
        ...state,
        submitting: true,
      }
    case 'TOGGLE_SESSION_ADD':
      return {
        ...state,
        sessionAdded: action.payload.sessionAdded,
        sessionPosts: action.payload.sessionPosts || [],
        sessionCategories: action.payload.sessionCategories || [],
        submitting: false,
      }
    case 'ADD_TOAST':
      return {
        ...state,
        fetching: false,
        submitting: false,
      }
    case 'CLEAR_SESSION':
      return {
        ...initialState,
        fetching: false
      }
    default:
      return state
  }
}

import React, { forwardRef, useRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography
} from '@material-ui/core'
import CloseIcon from '../assets/icons/CloseIcon'

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  paper: { minWidth: "300px" },
})

const MuiDialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose
        ? <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon width={16} color={'black'} />
          </IconButton>
        : null}
    </DialogTitle>
  )
})

const MuiDialog = forwardRef(
  ({ open, handleClose, title, children, ...formProps }, ref) => {
    const dialogRef = useRef()
    return (
      <Dialog
        fullWidth={true}
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={handleClose}
      >
        <MuiDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
        </MuiDialogTitle>
        <DialogContent ref={ref || dialogRef}>
          {children}
        </DialogContent>
      </Dialog>
    )
  }
)

MuiDialog.displayName = 'MuiDialog'

export default MuiDialog

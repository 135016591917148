export const updateFormState = ({ event, formState }) => {
  const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
  delete formState.formValidationErrors[event.target.name]
  return {
    ...formState,
    values: {
      ...formState.values,
      [event.target.name]: value
    },
    touched: {
      ...formState.touched,
      [event.target.name]: true
    },
    isTouched: true
  }
}

export const hasError = (field, formState) => (formState.touched[field] && formState.errors[field] ? true : false)
export const hasFormValidationError = (field, formErrorsList) => (formErrorsList[field] ? true : false)

const initialState = {
  guest: null,
  guestAdded: null,
  fetching: true,
  submitting: false
}

export default function guest (state = initialState, action) {
  switch (action.type) {
    case 'GUEST_FETCHING':
      return {
        ...state,
        fetching: true,
      }
    case 'GET_GUEST':
      return {
        ...state,
        guest: action.payload.guest,
        fetching: false,
        submitting: false,
      }
    case 'GUEST_SUBMIT_FORM_ACTION':
      return {
        ...state,
        submitting: true,
      }
    case 'TOGGLE_GUEST_ADD':
      return {
        ...state,
        guestAdded: action.payload.guestAdded,
        submitting: false,
      }
    case 'ADD_TOAST':
      return {
        ...state,
        fetching: false,
        submitting: false,
      }
    case 'CLEAR_GUEST':
      return {
        ...initialState,
        fetching: false
      }
    default:
      return state
  }
}

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getAllStages } from '../api/Stage'
import { getAllCharacters } from '../api/Character'
import { createGuest } from '../api/Guest'
import { createSession } from '../api/Session'
import { createSessionPost } from '../api/SessionPost'
import axios from 'axios'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import parse from 'html-react-parser'
import ArrowLeft from '../assets/icons/ArrowLeft'
import ArrowRight from '../assets/icons/ArrowRight'
import TwitterPost from '../components/TwitterPost'
import InstagramPost from '../components/InstagramPost'
import WhatsappPost from '../components/WhatsappPost'
import FacebookPost from '../components/FacebookPost'
import Correct from '../assets/icons/Correct'
import Wrong from '../assets/icons/Wrong'

const mapStateToProps = ({
  stages: { list: stagesList, fetching: stagesFetching },
  characters: { list: charactersList, fetching: charactersFetching },
  guest: { guestAdded: guestId, submitting: guestSubmitting },
  session: { sessionAdded: sessionId, submitting: sessionSubmitting, sessionPosts, sessionCategories },
}) => {
  return {
    stagesList,
    stagesFetching,
    charactersList,
    charactersFetching,
    guestId,
    guestSubmitting,
    sessionId,
    sessionSubmitting,
    sessionPosts,
    sessionCategories
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchGetAllStages: () => {
    dispatch(getAllStages({ paginate: 0 }))
  },
  dispatchGetAllCharacters: () => {
    dispatch(getAllCharacters({ paginate: 0 }))
  },
  dispatchCreateGuest: (data) => {
    dispatch(createGuest(data))
  },
  dispatchCreateSession: (item) => {
    dispatch(createSession(item))
  },
  dispatchClearSession: () => {
    dispatch({
      type: 'CLEAR_SESSION'
    })
  },
  dispatchCreateSessionPost: (item) => {
    dispatch(createSessionPost(item))
  }
})

const Game = ({
  stagesList,
  stagesFetching,
  charactersList,
  charactersFetching,
  dispatchGetAllStages,
  dispatchGetAllCharacters,
  dispatchCreateGuest,
  guestId,
  guestSubmitting,
  dispatchCreateSession,
  sessionId,
  sessionSubmitting,
  sessionPosts,
  sessionCategories,
  dispatchClearSession,
  dispatchCreateSessionPost
}) => {
  const [currentStep, setCurrentStep] = useState(1)
  const [currentStage, setCurrentStage] = useState('start')
  const [selectedCharacter, setSelectedCharacter] = useState(null)
  const [selectedPost, setSelectedPost] = useState(null)
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(null)
  const [finishedCategories, setFinishedCategories] = useState([])

  const [postIndex, setPostIndex] = useState(0)
  const [showAwards, toggleShowAwards] = useState(false)
  const [showRedo, setShowRedo] = useState(false)

  const [geoData, setGeoData] = useState({})

  const data = stagesList.find(e => e.stage === currentStage && e.step === currentStep)

  const getData = () => {
    axios.get('https://geolocation-db.com/json/').then((res) => {
      if (res.data) {
        setGeoData({
          ip: res.data.IPv4,
          country: res.data.country_name,
          city: res.data.city,
        })
      }
    }).catch((e) => console.log(e))
      .finally(() => {
        dispatchGetAllStages()
        dispatchGetAllCharacters()
      })
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  const handleGoBack = () => {
    if (currentStage === 'characterSelection') {
      if (currentStep === 1) {
        setCurrentStage('start')
      } else {
        const newStep = currentStep - 1
        setCurrentStep(newStep)
        if (newStep === 1) {
          setSelectedCharacter(null)
        }
      }
    }
    if (currentStage === 'posting') {
      setCurrentStage('characterSelection')
      setCurrentStep(3)
    }
  }

  const answerConfimationQuestion = (post, isCorrect) => {
    setSelectedPost(post)
    setIsCorrectAnswer(isCorrect)
    dispatchCreateSessionPost({ sessionId, postId: post.id, isCorrect: isCorrect })

    if (isCorrect) {
      const currentCategoryId = post.badgeId
      const tempFinishedCategories = finishedCategories
      tempFinishedCategories.push(currentCategoryId)
      setFinishedCategories(tempFinishedCategories)
    }
  }

  const handleClickOutside = () => {
    if (showAwards) toggleShowAwards(false)
  }

  return (
    <>
      {(stagesFetching || charactersFetching || stagesList.length === 0 || charactersList.length === 0 || guestSubmitting || sessionSubmitting) ? (
        <Backdrop
          open={true}
          style={{ zIndex: 200, position: "absolute" }}
        >
          <CircularProgress color="secondary" />
        </Backdrop>
      ) : (
        <>
          {showRedo &&
            <div className='popup popup-correct popup-redo'>
              <div className='popup-title'>
                {stagesList.find(e => e.stage === 'redo').content}
              </div>
              <div className='popup-action'>
                <button
                  className='action-btn'
                  onClick={() => {
                    setPostIndex(0)
                    setSelectedPost(null)
                    setSelectedCharacter(null)
                    setCurrentStage('characterSelection')
                    setCurrentStep(1)
                    setShowRedo(false)
                    setFinishedCategories([])
                  }}
                >{stagesList.find(e => e.stage === 'redo').textBeforeBtn}</button>
                <button
                  className='action-btn'
                  onClick={() => {
                    setCurrentStage('final')
                    setCurrentStep(1)
                    setShowRedo(false)
                    setFinishedCategories([])
                  }}
                >{stagesList.find(e => e.stage === 'redo').btnText}</button>
              </div>
            </div>
          }
          {selectedPost && isCorrectAnswer &&
          <div className='popup popup-correct'>
            <div className='popup-header'>
              {stagesList.find(e => e.stage === 'correctAnswer').title}
              <Correct />
            </div>
            <div className='popup-title'>
              {selectedPost.correctAnswerText}
            </div>
            <div className='popup-badge'>
              <img src={selectedPost.badge.icon} />
            </div>
            <div className='popup-action'>
              {stagesList.find(e => e.stage === 'correctAnswer').textBeforeBtn}
              <button
                className='action-btn'
                onClick={() => {
                  setSelectedPost(null)
                  setIsCorrectAnswer(null)
                  if (postIndex + 1 === sessionPosts.length) {
                    setCurrentStage('finishPosting')
                  } else {
                    setPostIndex(postIndex + 1)
                  }
                }}
              ><ArrowRight /></button>
            </div>
          </div>
          }
          {selectedPost && !isCorrectAnswer &&
          <div className='popup popup-worng'>
            <div className='popup-header'>
              {stagesList.find(e => e.stage === 'wrongAnswer').title}
              <Wrong />
            </div>
            <div className='popup-content'>
              {selectedPost.wrongAnswerText}
            </div>
            <div className='popup-action'>
              {stagesList.find(e => e.stage === 'wrongAnswer').textBeforeBtn}
              <button
                className='action-btn'
                onClick={() => {
                  setSelectedPost(null)
                  setIsCorrectAnswer(null)
                  if (postIndex + 1 === sessionPosts.length) {
                    setCurrentStage('finishPosting')
                  } else {
                    setPostIndex(postIndex + 1)
                  }
                }}
              ><ArrowRight /></button>
            </div>
          </div>
          }
          {showAwards &&
          <div className='badges-panel'>
            <div className='badges'>
              {sessionCategories.map((cat, index) => {
                return (
                  <div
                    className={'badge' + (finishedCategories.includes(cat.id) ? '' : ' not-achieved')}
                    key={index}
                  >
                    <img src={cat.icon} title={cat.name} />
                  </div>
                )
              })}
            </div>
          </div>
          }
        <div className={'app' + ((selectedPost || showRedo || showAwards) ? ' popup-open' : '')}>
          <div className='header'></div>
          <div className='header-text'>
            Info Vax
          </div>
          {(currentStage === 'characterSelection' || (currentStage === 'posting' && postIndex === 0)) &&
            <div className='header-btn'>
              <button className='go-back-btn' onClick={handleGoBack}><ArrowRight /></button>
            </div>
          }
          <div className={'container' + (currentStage === 'characterSelection' ? ' pb-0' : (currentStage === 'finishPosting' ? ' pb-150' : ''))}>
            {(currentStage === 'start') && data &&
            <>
              {data.title &&
              <div className='title'>
                {data.title}
              </div>
              }
              {data.content &&
              <div className='content'>
                {parse(data.content)}
              </div>
              }
              <div className='screen-action screen-action-vertical'>
              {data.textBeforeBtn &&
              <div className='text-before-btn'>
                {data.textBeforeBtn}
              </div>
              }
              {data.btnText &&
              <button
                className='btn'
                onClick={() => {
                  if (guestId === null) {
                    dispatchCreateGuest(geoData)
                  }
                  setCurrentStage('characterSelection')
                  setCurrentStep(1)
                  setSelectedCharacter(null)
                }}
              >
                <ArrowLeft />
                {data.btnText}
              </button>
              }
              </div>
            </>
            }
            {(currentStage === 'characterSelection') && data &&
              <>
                {currentStep === 1 &&
                  <>
                    {data.title &&
                    <div className='title'>
                      {data.title}
                    </div>
                    }
                    <div className='characters-container'>
                      {charactersList.map((char, index) => {
                        return (
                          <div
                            key={index}
                            className='character-container'
                            onClick={() => {
                              setCurrentStage('characterSelection')
                              setCurrentStep(2)
                              setSelectedCharacter(char)
                            }}
                          >
                            <div className='image-container' style={{ '--character-color': '#' + char.colorHEX }}>
                              <img src={char.characterPicture} />
                            </div>
                            <div className='character-name' style={{ '--character-color': '#' + char.colorHEX }}>{char.fullName}</div>
                            <div className='character-profession' style={{ '--character-color': '#' + char.colorHEX }}>{char.profession}</div>
                          </div>
                        )
                      })}
                    </div>
                  </>
                }
                {currentStep === 2 &&
                  <div className='row selected-character-section'>
                    <div className='col col-30'>
                      <div className='selected-character-container'>
                        <div className='image-container' style={{ '--character-color': '#' + selectedCharacter.colorHEX }}>
                          <img src={selectedCharacter.characterPicture} />
                        </div>
                        <div className='character-name' style={{ '--character-color': '#' + selectedCharacter.colorHEX }}>{selectedCharacter.fullName}</div>
                      </div>
                    </div>
                    <div className='col col-70'>
                      {selectedCharacter.profession &&
                      <div className='character-profession' style={{ '--character-color': '#' + selectedCharacter.colorHEX }}>
                        {selectedCharacter.profession}
                      </div>
                      }
                      {selectedCharacter.bio &&
                      <div className='character-bio'>
                        {parse(selectedCharacter.bio)}
                      </div>
                      }
                      {data.btnText &&
                      <button
                        className='btn'
                        onClick={() => {
                          setCurrentStep(3)
                        }}
                      >
                        <ArrowLeft />
                        {data.btnText}
                      </button>
                      }
                    </div>
                  </div>
                }
                {currentStep === 3 &&
                  <>
                    {data.title &&
                    <div className='title'>
                      {data.title}
                    </div>
                    }
                    <div className='selected-character-container'>
                      <div className='image-container' style={{ '--character-color': '#' + selectedCharacter.colorHEX }}>
                        <img src={selectedCharacter.characterPicture} />
                      </div>
                      <div className='character-name' style={{ '--character-color': '#' + selectedCharacter.colorHEX }}>{selectedCharacter.fullName}</div>
                    </div>
                    {data.textBeforeBtn &&
                    <div className='text-before-btn'>
                      {data.textBeforeBtn}
                    </div>
                    }
                    {data.btnText &&
                    <button
                      className='btn'
                      onClick={() => {
                        setCurrentStep(1)
                        setCurrentStage('posting')
                        dispatchCreateSession({ guestId, characterId: selectedCharacter.id })
                      }}
                    >
                      <ArrowLeft />
                      {data.btnText}
                    </button>
                    }
                  </>
                }
              </>
            }
            {(currentStage === 'posting') && data &&
              <>
                <div className='character-container no-expand' onClick={() => toggleShowAwards(true)}>
                  <div className='image-container' style={{ '--character-color': '#' + selectedCharacter.colorHEX }}>
                    <img src={selectedCharacter.characterPicture} />
                  </div>
                  <div className='character-name' style={{ '--character-color': '#' + selectedCharacter.colorHEX }}>{selectedCharacter.fullName}</div>
                </div>
                <div className="shared-post">
                  {sessionPosts[postIndex].type === 'twitter' &&
                  <TwitterPost
                    selectedCharacter={selectedCharacter}
                    post={sessionPosts[postIndex]}
                  />
                  }
                  {sessionPosts[postIndex].type === 'instagram' &&
                  <InstagramPost
                    selectedCharacter={selectedCharacter}
                    post={sessionPosts[postIndex]}
                  />
                  }
                  {sessionPosts[postIndex].type === 'whatsapp' &&
                  <WhatsappPost
                    selectedCharacter={selectedCharacter}
                    post={sessionPosts[postIndex]}
                  />
                  }
                  {sessionPosts[postIndex].type === 'facebook' &&
                  <FacebookPost
                    selectedCharacter={selectedCharacter}
                    post={sessionPosts[postIndex]}
                  />
                  }
                </div>

                <div className="post-actions">
                  {sessionPosts[postIndex].confirmationQuestion &&
                  <div className='action-text'>
                    {sessionPosts[postIndex].confirmationQuestion}
                  </div>
                  }
                  <div className="actions">
                    <button
                      className="btn btn-true"
                      onClick={() => {
                        answerConfimationQuestion(sessionPosts[postIndex], sessionPosts[postIndex].isTrue === true)
                      }}
                    >
                      {sessionPosts[postIndex].btnTrue}
                    </button>
                    <button
                      className="btn btn-false"
                      onClick={() => {
                        answerConfimationQuestion(sessionPosts[postIndex], sessionPosts[postIndex].isTrue === false)
                      }}
                    >
                      {sessionPosts[postIndex].btnFalse}
                    </button>
                  </div>
                  <div className='current-post'>Round {postIndex + 1}</div>
                  <div className='progress-bar'><div className='current-progress' style={{ width: (100 / 7) * (postIndex + 1) + '%' }}></div></div>
                </div>
              </>
            }
            {(currentStage === 'finishPosting') && data &&
              <>
                <div className='awards-section'>
                  <div className='selected-character-container'>
                    <div className='image-container' style={{ '--character-color': '#' + selectedCharacter.colorHEX }}>
                      <img src={selectedCharacter.characterPicture} />
                    </div>
                    <div className='character-name' style={{ '--character-color': '#' + selectedCharacter.colorHEX }}>{selectedCharacter.fullName}</div>
                  </div>
                  <div className='awards'>
                    <div className='awards-content'>
                      {selectedCharacter.finalText}
                    </div>
                    Badges gagnés :
                    <div className='badges'>
                      {sessionCategories.map((cat, index) => {
                        return (
                          <div
                            className={'badge' + (finishedCategories.includes(cat.id) ? '' : ' not-achieved')}
                            key={index}
                          >
                            <img src={cat.icon} title={cat.name} />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                {data.textBeforeBtn &&
                <div className='screen-action'>
                  {data.textBeforeBtn}
                  <div className='action-btn' onClick={() => setShowRedo(true)}>
                    <ArrowRight />
                  </div>
                </div>
                }
              </>
            }
            {(currentStage === 'final') && data &&
              <div className='final-section'>
                {data.content &&
                <div className='content'>
                  {parse(data.content)}
                </div>
                }
                {data.btnText &&
                <button
                  className='btn'
                  onClick={() => {
                    setPostIndex(0)
                    setSelectedPost(null)
                    setSelectedCharacter(null)
                    setCurrentStage('start')
                    setCurrentStep(1)
                    setFinishedCategories([])
                  }}
                >
                  {data.btnText}
                </button>
                }
              </div>
            }
          </div>
        </div>
        </>
      )}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Game)

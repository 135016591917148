import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { getUserProfile } from '../api/Admin'

const mapDispatchToProps = dispatch => ({
  getUserProfile: (currentLocation) => {
    dispatch(getUserProfile(currentLocation))
  }
})

const Authentication = ({ getUserProfile, children }) => {
  const location = useLocation()

  useEffect(() => {
    if (location.pathname !== '/') {
      getUserProfile(location.pathname)
    }
  }, [])
  return (
    <div>
      {children}
    </div>
  )
}

export default connect(null, mapDispatchToProps)(Authentication)

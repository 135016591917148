import { toast } from 'react-toastify'

const initialState = { error: [], success: [] }

const isNetworkError = err => {
  return !!err.isAxiosError && !err.response
}

const notify = (content, options) => {
  if (isNetworkError(content)) {
    toast('Network Error', options)
  }
  if (content != null && content.length > 0) {
    if (Array.isArray(content)) {
      content.map(line => toast(line, options))
    } else {
      toast(content, options)
    }
  }
}

export const addToast = ({ error, success }) => async dispatch => {
  dispatch({
    type: 'ADD_TOAST',
    payload: {
      error,
      success
    }
  })
}

export const dispatchError = errorResponse => async dispatch => {
  if (errorResponse.status === 500) {
    notify('حدث خطأ', { type: toast.TYPE.ERROR })
  } else if (errorResponse.status === 422) {
    validationErrors(errorResponse.data)
  } else if (errorResponse.status === 403) {
    notify('لا يوجد صلاحية', { type: toast.TYPE.ERROR })
  } else if (errorResponse.status === 404) {
    notify('غير موجود', { type: toast.TYPE.ERROR })
  } else if (errorResponse.status === 405) {
    notify('لا يوجد صلاحية', { type: toast.TYPE.ERROR })
  } else if (errorResponse.status === 400) {
    notify('حدث خطأ', { type: toast.TYPE.ERROR })
  } else {
    notify('حدث خطأ', { type: toast.TYPE.ERROR })
  }
  dispatch({
    type: 'ADD_TOAST',
    payload: {
      error: ''
    }
  })
}

const validationErrors = (errorResponse) => {
  if (errorResponse.errors) {
    Object.keys(errorResponse.errors)
      .forEach(key => {
        notify(errorResponse.errors[key][0], { type: toast.TYPE.ERROR })
      })
  } else if (errorResponse.error) {
    Object.keys(errorResponse.error)
      .forEach((key) => {
        if (key !== 'status_code') {
          notify(errorResponse.error[key], { type: toast.TYPE.ERROR })
        }
      })
  } else {
    Object.keys(errorResponse)
      .forEach((key) => {
        if (key !== 'status_code') {
          notify(errorResponse[key], { type: toast.TYPE.ERROR })
        }
      })
  }
}

export default function errorsToast (state = initialState, action) {
  switch (action.type) {
    case 'ADD_TOAST':
      action.payload.error &&
      notify(action.payload.error, { type: toast.TYPE.ERROR })
      action.payload.success &&
      notify(action.payload.success, { type: toast.TYPE.SUCCESS })
      return { ...state, ...action.payload }
    default:
      return state
  }
}

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getGuest } from '../../api/Guest'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import moment from 'moment'
import Table from '../../components/Table'
import SessionPosts from './SessionPosts'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  fixedHeight: {
    height: 200
  }
}))

const mapStateToProps = ({
  guest: { guest, fetching },
  sessionPosts: { list: sessionPostsList, fetching: sessionPostsFetching, showSessionPostsModal },
}) => {
  return {
    guest,
    fetching,
    sessionPostsList,
    sessionPostsFetching,
    showSessionPostsModal
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchGetGuest: (id) => {
    dispatch(getGuest(id, { 'scopes[]': 'getSessions' }))
  },
  dispatchClearGuest: () => {
    dispatch({
      type: 'CLEAR_GUEST'
    })
  },
  toggleSessionPostsModal: (showModal) => {
    dispatch({
      type: 'TOGGLE_SESSION_POSTS_MODAL',
      payload: {
        showSessionPostsModal: showModal
      },
    })
    if (!showModal) {
      dispatch({
        type: 'CLEAR_SESSION_POSTS'
      })
    }
  },
})

const List = ({
  guest,
  fetching,
  dispatchGetGuest,
  dispatchClearGuest,
  showSessionPostsModal,
  toggleSessionPostsModal,
  match
}) => {
  const id = match.params.id
  const classes = useStyles()
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)
  const [selectedItemId, setSelectedItemId] = useState(null)
  const columns = [
    {
      title: "Character",
      render: (rowData) => { return rowData.character.fullName }
    },
    {
      title: "Date/Time",
      field: "createdAt",
      render: (rowData) => { return moment(rowData.createdAt).format('D MMM YYYY HH:mm') }
    }
  ]

  useEffect(() => {
    dispatchGetGuest(id)
    return () => {
      dispatchClearGuest()
    }
  }, [])

  return (
    <>
      {showSessionPostsModal && (
        <SessionPosts
          open={showSessionPostsModal}
          sessionObject={guest.sessions.find(e => e.id === selectedItemId)}
          sessionId={selectedItemId}
          handleClose={() => toggleSessionPostsModal(false)}
        />
      )}
      {guest &&
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={fixedHeightPaper}>
            <Typography component="h2" variant="h5" color="primary" gutterBottom>
              IP Address
            </Typography>
            <Typography component="p" variant="h4">
              {guest.ipAddress}

            </Typography>
            {guest.country && guest.city &&
            <Typography component="p" variant="body1">
              <br/>
              {guest.country + ' / ' + guest.city}
            </Typography>
            }
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={fixedHeightPaper}>
            <Typography component="h2" variant="h5" color="primary" gutterBottom>
              User Agent
            </Typography>
            <Typography component="p" variant="body2">
              {guest.userAgent}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={fixedHeightPaper}>
            <Typography component="h2" variant="h5" color="primary" gutterBottom>
              Date/Time
            </Typography>
            <Typography component="p" variant="h4">
              {moment(guest.createdAt).format('D MMM YYYY HH:mm')}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={fixedHeightPaper}>
            <Typography component="h2" variant="h5" color="primary" gutterBottom>
              Number of Tries
            </Typography>
            <Typography component="p" variant="h4">
              {guest.sessions.length}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Typography component="h2" variant="h4" color="primary" gutterBottom>
            Tries
          </Typography>
          {guest.sessions && guest.sessions.length > 0 ? (
          <Table
            allowDelete={false}
            allowEdit={true}
            allowAdd={false}
            style={{ maxWidth: "100%" }}
            columns={columns}
            rowsPerPage={guest.sessions.length}
            data={guest.sessions}
            isLoading={fetching}
            pageNumber={1}
            totalData={guest.sessions.length}
            onRowUpdate={(item) => {
              setSelectedItemId(item.id)
              toggleSessionPostsModal(true)
            }}
            updateTooltip="View Posts"
            title="Tries"
          />
          ) : (
            <Paper className={fixedHeightPaper}>
              <Typography component="p" variant="body1">
                No Data
              </Typography>
            </Paper>
          )}
        </Grid>
      </Grid>
      }
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(List)

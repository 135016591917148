import React from 'react'

const SVG = ({
  color = '#fff',
  width = '100%',
  ...restProps
}) => (
  <svg
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20.31 24.997"
    {...restProps}
  >
    <g transform="translate(0 0)">
      <path
        fill={color}
        d="M1097.921,808.207h18.748a.789.789,0,0,0,0-1.578h-3.906V804.26a.785.785,0,0,0-.781-.789h-9.374a.785.785,0,0,0-.781.789v2.368h-3.906a.789.789,0,0,0,0,1.578Zm5.468-3.157h7.812v1.579h-7.812Z"
        transform="translate(-1097.14 -803.471)"
      />
      <path
        fill={color}
        d="M1116.676,843.471h-.007a.78.78,0,0,0-.781.771l-.149,16.354H1099c-.111-5.92-.3-16.016-.3-16.347a.781.781,0,0,0-1.562,0c0,.447.3,16.458.313,17.14a.78.78,0,0,0,.781.764h18.279a.78.78,0,0,0,.781-.771l.156-17.125A.78.78,0,0,0,1116.676,843.471Z"
        transform="translate(-1097.14 -837.157)"
      />
      <path
        fill={color}
        d="M1125.14,850.012v13.74a.789.789,0,0,0,1.579,0v-13.74a.789.789,0,1,0-1.579,0Z"
        transform="translate(-1120.769 -842.262)"
      />
      <path
        fill={color}
        d="M1157.14,850.012v13.74a.789.789,0,0,0,1.579,0v-13.74a.789.789,0,1,0-1.579,0Z"
        transform="translate(-1147.774 -842.262)"
      />
      <path
        fill={color}
        d="M1189.141,850.012v13.74a.789.789,0,1,0,1.579,0v-13.74a.789.789,0,1,0-1.579,0Z"
        transform="translate(-1174.78 -842.262)"
      />
    </g>
  </svg>
)

export default SVG

import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import cookie from 'react-cookies'
import Header from './Header'
import CssBaseline from '@material-ui/core/CssBaseline'
import Sidebar from './Sidebar'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Copyrights from './Copyrights'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
}))

const PrivateLayout = ({ component: Component, title, ...restProps }) => {
  const isAuthenticated = cookie.load('access_token')
  const classes = useStyles()

  const [open, setOpen] = React.useState(true)
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    isAuthenticated
      ? <div className={classes.root}>
          <CssBaseline />
          <Header title={title} openDrawer={open} handleDrawerOpen={handleDrawerOpen} />
          <Sidebar openDrawer={open} handleDrawerClose={handleDrawerClose}/>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
              <Component {...restProps} />
              <Box pt={4}>
                <Copyrights />
              </Box>
            </Container>
          </main>
        </div>
      : <Redirect to='/panel/login' />
  )
}

const PrivateRoute = ({ component, restricted, title, ...restProps }) => {
  return (
    <Route
      {...restProps}
      exact
      render={props => <PrivateLayout component={component} restricted={restricted} title={title} {...props} />}
    />
  )
}

export default PrivateRoute

import React from 'react'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

const Copyrights = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'All rights reserved '}
      <Link color="inherit" href="https://game.com/" target="_blank">
        game.com
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  )
}

export default Copyrights

import React, { Fragment } from 'react'
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  FormHelperText,
  Checkbox,
  Typography
} from '@material-ui/core'
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete'
import CloseIcon from '../assets/icons/CloseIcon'
import * as _ from 'lodash'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import IconButton from '@material-ui/core/IconButton'
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined'
import { startCase, toLower } from 'lodash'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

const filter = createFilterOptions()

const FormElement = ({
  elementType,
  items,
  label,
  value,
  valueField,
  titleField,
  staticContext,
  dispatch,
  ...restProps
}) => {
  const helperText = restProps.helperText
  valueField = valueField || 'id'
  titleField = titleField || 'name'
  switch (elementType) {
    case 'text':
      return (
        <TextField
          variant="outlined"
          margin="normal"
          value={value || ''}
          fullWidth
          label={label}
          {...restProps}
        />
      )
    case 'text-with-placeholder':
      return (
        <TextField value={value} fullWidth placeholder={label} {...restProps} />
      )
    case 'file':
      const {
        onFileChange,
        onChange
      } = restProps
      delete restProps.onFileChange
      delete restProps.onChange
      return (
        <TextField
          type='file'
          variant="outlined"
          margin="normal"
          value={value || ''}
          fullWidth
          label={label}
          onChange={(event) => {
            if (event.target.files.length > 0) {
              onFileChange(event.target.files[0])
            }
            onChange(event)
          }}
          {...restProps}
        />
      )
    case 'select':
      delete restProps.helperText
      return items
        ? <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel>{label}</InputLabel>
            <Select
              label={label}
              value={value || []}
              IconComponent={props => (
                <KeyboardArrowDownIcon width={16} height={16} {...props} />
              )}
              {...restProps}
            >
              <MenuItem value="" disabled>
                <em>Select {label}</em>
              </MenuItem>
              {items.map((c, index) =>
                c[valueField]
                  ? <MenuItem key={index} value={c[valueField]}>
                      {c[titleField]}
                    </MenuItem>
                  : <MenuItem key={index} value={c}>
                      {c}
                    </MenuItem>
              )}
            </Select>
            <FormHelperText>{helperText}</FormHelperText>
          </FormControl>
        : <Fragment />
    case 'autocomplete':
      const allSelected =
        restProps.multiple &&
        items &&
        value &&
        items.length === value.length &&
        items.length !== 0
      delete restProps.helperText
      delete restProps.error
      const {
        onChange: autocompleteOnChange,
        onSelectChange,
        toggleOpen,
        setDialogValue,
        creatable
      } = restProps
      delete restProps["onChange"]
      delete restProps["onSelectChange"]
      delete restProps["toggleOpen"]
      delete restProps["setDialogValue"]
      delete restProps["creatable"]
      if (
        items &&
        items.length > 0 &&
        (!items[0][valueField] || !items[0][titleField])
      ) {
        items = items.map(i => {
          return {
            [valueField]: i,
            [titleField]: startCase(toLower(i))
          }
        })
      }
      return items ? (
        <FormControl
          variant="outlined"
          fullWidth
          margin="normal"
          style={restProps.style}
        >
          <Autocomplete
            openOnFocus={true}
            label={label}
            value={
              restProps.freeSolo
                ? value
                : restProps.multiple
                ? value
                  ? items.filter(e => value.includes(e[valueField]))
                  : []
                : value
                  ? items.filter(e => value === e[valueField])[0]
                  : null
            }
            options={items}
            getOptionLabel={option => {
              return (
                option[titleField] ||
                _.get(
                  items.find(c => c[valueField] === option),
                  titleField,
                  option
                )
              )
            }}
            onChange={(event, newValue, reason) => {
              const inputValue =
                typeof newValue === "string"
                  ? newValue
                  : restProps.multiple
                  ? newValue &&
                  newValue.length > 0 &&
                  newValue[newValue.length - 1].inputValue
                  : newValue && newValue.inputValue
              if (inputValue && creatable) {
                // timeout to avoid instant validation of the dialog's form.
                setTimeout(() => {
                  toggleOpen(true)
                  setDialogValue(inputValue)
                })
              } else {
                if (restProps.freeSolo) {
                  autocompleteOnChange({
                    target: {
                      name: restProps["name"],
                      type: "autocomplete",
                      value: newValue
                    }
                  })
                } else if (
                  restProps.multiple &&
                  newValue.find(option => option[valueField] === "select-all")
                ) {
                  autocompleteOnChange({
                    target: {
                      name: restProps["name"],
                      type: "autocomplete",
                      value: allSelected ? [] : items.map(i => i[valueField])
                    }
                  })
                } else {
                  const value = newValue
                    ? restProps.multiple
                      ? newValue.map(a => a[valueField])
                      : newValue[valueField]
                    : null
                  if (onSelectChange) {
                    onSelectChange(value)
                  }
                  autocompleteOnChange({
                    target: {
                      name: restProps["name"],
                      type: "autocomplete",
                      value: value
                    }
                  })
                }
              }
            }}
            renderOption={(option, { selected, inputValue }) => {
              const matches = match(option[titleField], inputValue)
              const parts = parse(option[titleField], matches)
              const selectAllProps =
                option[valueField] === "select-all" // To control the state of 'select-all' checkbox
                  ? { checked: allSelected }
                  : {}
              if (restProps.multiple) {
                if (option.freeSolo) {
                  return (
                    <>
                      <IconButton style={{ padding: "9px" }}>
                        <AddBoxOutlinedIcon style={{ color: "black" }} />
                      </IconButton>
                      <Typography noWrap>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{ fontWeight: part.highlight ? 700 : 400 }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </Typography>
                    </>
                  )
                } else {
                  return (
                    <>
                      <Checkbox
                        color="primary"
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={
                          <CheckBoxIcon color="action" fontSize="small" />
                        }
                        checked={selected}
                        {...selectAllProps}
                      />
                      <Typography
                        noWrap
                        style={{
                          fontWeight:
                            option[valueField] === "select-all"
                              ? "bold"
                              : "normal"
                        }}
                      >
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{ fontWeight: part.highlight ? 700 : 400 }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </Typography>
                    </>
                  )
                }
              } else {
                return (
                  <Typography noWrap>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </Typography>
                )
              }
            }}
            renderInput={params => {
              let placeholder = "Select "
              if (!restProps.multiple) placeholder += "a "
              placeholder += label.toLowerCase()
              return (
                <TextField
                  placeholder={placeholder}
                  label={label}
                  variant="outlined"
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password" // disable autocomplete and autofill
                  }}
                />
              )
            }}
            popupIcon={<KeyboardArrowDownIcon width={16} />}
            closeIcon={<CloseIcon width={16} color="#3f51b5" />}
            filterOptions={(options, params) => {
              const filtered = filter(options, params)

              const isExist =
                options.findIndex(opt =>
                  restProps.multiple
                    ? params.inputValue === opt[titleField]
                    : params.inputValue === opt
                ) === -1
                  ? false
                  : true

              if (restProps.multiple && filtered.length !== 0) {
                filtered.unshift({
                  [titleField]: allSelected ? "Deselect all" : "Select all",
                  [valueField]: "select-all"
                })
              }

              if (!creatable) return filtered

              if (params.inputValue !== "" && !isExist) {
                filtered.push({
                  inputValue: params.inputValue,
                  [titleField]: `Add "${params.inputValue}"`,
                  freeSolo: true
                })
              }

              return filtered
            }}
            freeSolo={creatable}
            {...restProps}
          />
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      ) : (
        <Fragment />
      )
    case "switch":
      delete restProps["helperText"]
      delete restProps["error"]
      delete restProps["color"]
      return (
        <FormControl margin="normal" fullWidth>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={value ? true : false}
                {...restProps}
              />
            }
            label={label}
          />
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      )
    default:
      return <Fragment />
  }
}

export default FormElement

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Table from '../../components/Table'
import DialogConfirmation from '../../components/DialogConfirmation'
import { Container } from '@material-ui/core'
import CreateUpdate from './CreateUpdate'
import { getAllPosts, deletePost } from '../../api/Post'
import { getAllCategories } from '../../api/Category'
import { getAllCharacters } from '../../api/Character'
import TableFilters from '../../components/TableFilters'

const mapStateToProps = ({
   post: { showUpdateModal, showAddModal, showDeleteDialog },
   posts: { list, totalData, fetching },
   categories: { list: categoriesList },
   characters: { list: charactersList },
}) => {
  return {
    list,
    totalData,
    fetching,
    showUpdateModal,
    showAddModal,
    showDeleteDialog,
    categoriesList,
    charactersList
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchGetAll: (filters, pageNumber) => {
    dispatch(getAllPosts({ ...filters, page: pageNumber }))
  },
  submitDeleteItem: (itemId) => {
    dispatch(deletePost(itemId))
  },
  toggleUpdateModal: (showUpdateModal) => {
    dispatch({
      type: 'TOGGLE_POST_UPDATE',
      payload: {
        showUpdateModal: showUpdateModal,
      },
    })
  },
  toggleAddModal: (showAddModal) => {
    dispatch({
      type: 'TOGGLE_POST_ADD',
      payload: {
        showAddModal: showAddModal,
      },
    })
  },
  toggleDeleteDialog: (showDeleteDialog) => {
    dispatch({
      type: "TOGGLE_POST_DELETE",
      payload: {
        showDeleteDialog: showDeleteDialog,
      },
    })
  },
  dispatchGetAllCategories: () => {
    dispatch(getAllCategories({ paginate: 0, order: 'ASC' }))
  },
  dispatchGetAllCharacters: () => {
    dispatch(getAllCharacters({ paginate: 0, order: 'ASC' }))
  }
})

const defaultFilters = {
  categoryId: null,
  characterId: null
}

const List = ({
  dispatchGetAllCategories,
  categoriesList,
  dispatchGetAllCharacters,
  charactersList,
  list,
  totalData,
  fetching,
  dispatchGetAll,
  submitDeleteItem,
  showUpdateModal,
  showAddModal,
  showDeleteDialog,
  toggleUpdateModal,
  toggleAddModal,
  toggleDeleteDialog
}) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [selectedItemId, setSelectedItemId] = useState(null)
  const [filters, setFilters] = useState(defaultFilters)

  const columns = [
    { title: "Character", field: "character.fullName" },
    { title: "Badge", field: "category.name" },
    { title: "Post", field: "post" }
  ]

  useEffect(() => {
    if (!showAddModal && !showUpdateModal && !showDeleteDialog) {
      dispatchGetAll(filters, pageNumber)
    }
  }, [showAddModal, showUpdateModal, showDeleteDialog])

  useEffect(() => {
    dispatchGetAllCategories()
    dispatchGetAllCharacters()
  }, [])

  const handleFiltersChange = (filters) => {
    setFilters(filters)
    dispatchGetAll(filters, 1)
  }

  return (
    <Container maxWidth="xl">
      {showAddModal && (
        <CreateUpdate
          open={showAddModal}
          handleClose={() => toggleAddModal(false)}
        />
      )}
      {showUpdateModal && (
        <CreateUpdate
          open={showUpdateModal}
          handleClose={() => toggleUpdateModal(false)}
          postId={selectedItemId}
        />
      )}
      <DialogConfirmation
        open={showDeleteDialog}
        handleClose={() => toggleDeleteDialog(false)}
        handleConfirm={() => submitDeleteItem(selectedItemId)}
        title="Delete Confirmation"
        message="Are you sure you want to delete?"
      />

      <TableFilters
        filters={filters}
        handleFiltersChange={handleFiltersChange}
        data={{
          categories: categoriesList,
          characters: charactersList
        }}
      ></TableFilters>

      <Table
        allowDelete={true}
        allowEdit={true}
        allowAdd={true}
        style={{ maxWidth: "100%" }}
        columns={columns}
        rowsPerPage={10}
        data={list}
        isLoading={fetching}
        pageNumber={pageNumber}
        onChangePage={(newPage) => {
          setPageNumber(newPage)
          dispatchGetAll(filters, newPage)
        }}
        totalData={totalData}
        onRowAdd={() => toggleAddModal(true)}
        onRowUpdate={(item) => {
          setSelectedItemId(item.id)
          toggleUpdateModal(true)
        }}
        onRowDelete={(item) => {
          setSelectedItemId(item)
          toggleDeleteDialog(true)
        }}
        addTooltip="Add Post"
        title="Posts"
      />
    </Container>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(List)

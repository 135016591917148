import moment from 'moment'
import parse from 'html-react-parser'
import React from 'react'

const FacebookPost = ({ selectedCharacter, post }) => {
  return (
    <div className="facebook-post">
    <div className="container">
    <div className="post">
      <div className="post__header">
        <div className="header__left">
          <a href="#" className="post__author-pic" style={{ '--character-color': '#' + selectedCharacter.colorHEX }}>
            <img src={selectedCharacter.characterPicture} />
          </a>
          <div className="post__author author">
              <span className="author__name">
                <a href="#">{selectedCharacter.fullName}</a>
              </span>
            <i className="author__verified"></i>
          </div>
          <span className="post__date">
              <a href="#">{moment().format('DD') + ' ' + moment().locale('ar').format('MMM')}</a>
            </span>
          <span className="post__date-privacy-separator">&nbsp;·</span>
          <i className="post__privacy"></i>
        </div>
        <div className="header__right">
          <div className="post__options options">
            <i className="options__icon"></i>
          </div>
        </div>
      </div>
      <div className="post__content">
        <p className="content__paragraph">
          {parse(post.post)}
        </p>
        {post.postPicture &&
        <img src={post.postPicture} className="content__image"/>
        }
      </div>
      <div className="post__footer">
        <div className="footer__reactions reactions">
          <div className="reactions__emojis emojis">
            <img src="images/facebook/like.svg" className="emojis__like"/>
            <img src="images/facebook/haha.svg" className="emojis__haha"/>
            <img src="images/facebook/love.svg" className="emojis__love"/>
            <span className="emojis__count">
                <a href="#">{post.likesCount}k</a>
              </span>
          </div>
          <div className="reactions__comment-shares">
              <span className="comment-shares__comments"
              ><a href="#">{post.commentsCount} Comments</a></span
              >
            <span className="comment-shares__shares"
            ><a href="#">{post.retweetCount} Shares</a></span
            >
          </div>
        </div>
        <div className="footer__buttons buttons">
            <span className="buttons__like like">
              <i className="like__icon"></i>Like
            </span>
          <span className="buttons__comment comment"
          ><i className="comment__icon"></i>Comment</span
          >
          <span className="buttons__share share"
          ><i className="share__icon"></i>Share</span
          >
        </div>
      </div>
    </div>
    </div>
    </div>
  )
}

export default FacebookPost

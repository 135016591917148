import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle
} from 'react'
import { Button, CircularProgress, Box, Typography } from '@material-ui/core'
import validate from 'validate.js'
import * as _ from 'lodash'
import {
  updateFormState,
  hasError,
  hasFormValidationError
} from './formHelper'
import FormElement from '../components/FormElement'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { Prompt } from 'react-router'

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    margin: '0 8px'
  },
  buttonProgress: {
    color: '#1a1a1a',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  saved: {
    textAlign: 'center',
    margin: 4
  }
}))

const mapStateToProps = ({ errorsForm: { list: formErrorsList } }) => {
  return {
    formErrorsList
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchClearErrorsForm: () => {
    dispatch({
      type: 'SET_FORM_ERRORS',
      payload: {
        list: []
      }
    })
  }
})

const Form = forwardRef(
  (
    {
      onSubmit,
      submitting,
      submitLabel,
      children,
      validationSchema,
      initialValues,
      fetchingData,
      readonly,
      icon: Icon,
      cancelLabel,
      showCancelButton,
      backButtonLabel,
      showBackButton,
      onCancelButtonClick,
      onBackButtonClick,
      success,
      formErrorsList,
      dispatchClearErrorsForm,
      promptIsTouched = true
    },
    ref
  ) => {
    const classes = useStyles()
    const timer = React.useRef()

    const [showSuccess, setShowSuccess] = React.useState(false)

    const [formState, setFormState] = useState({
      isValid: false,
      values: { ...initialValues },
      isTouched: false,
      touched: {},
      errors: {},
      formValidationErrors: {}
    })

    useEffect(() => {
      if (!fetchingData && initialValues) {
        setFormState({
          ...formState,
          values: initialValues
        })
      }
    }, [fetchingData])

    useEffect(() => {
      validate.validators.optional = (value, options) => {
        return !_.isEmpty(value) ? validate.single(value, options) : null
      }

      const errors = validate(formState.values, validationSchema)

      setFormState(formState => ({
        ...formState,
        isValid: !errors,
        errors: errors || {},
        formValidationErrors: formErrorsList || {}
      }))
    }, [formState.values, formErrorsList])

    React.useEffect(() => {
      if (success && success !== '') {
        setShowSuccess(true)
        timer.current = window.setTimeout(() => {
          setShowSuccess(false)
        }, 5000)
        setFormState(formState => ({
          ...formState,
          isTouched: false
        }))
      }
      return () => {
        dispatchClearErrorsForm()
      }
    }, [success])

    useImperativeHandle(ref, () => ({
      updateValues (values) {
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            ...values
          }
        }))
      },
      checkIfTouched () {
        return formState.isTouched
      }
    }))

    const handleFormChange = event => {
      setFormState(updateFormState({ event, formState }))
    }

    validate.validators.customPassword = function (value) {
      let error = customValidatePasswordPattren(
        value,
        '^(?=.*[a-z])(.){1,}$',
        'must contains one lowercase letter'
      )
      if (!error) {
        error = customValidatePasswordPattren(
          value,
          '^(?=.*[A-Z])(.){1,}$',
          'must contains one uppercase letter'
        )
      }
      if (!error) {
        error = customValidatePasswordPattren(
          value,
          '^(?=.*[0-9])(.){1,}$',
          'must contains one numeric digit'
        )
      }
      if (!error) {
        error = customValidatePasswordPattren(
          value,
          '^(?=.*[~!@#$%^&*_=+?-])(.){1,}$',
          'must contains one special character ~ ! @ # $ % ^ & * - _ = + ?'
        )
      }
      return error
    }

    const customValidatePasswordPattren = (value, pattren, errorMessage) => {
      const regExp = new RegExp(pattren)
      if (!regExp.test(value)) {
        return errorMessage
      } else {
        return null
      }
    }

    return fetchingData
      ? <Box minWidth={300} m={10} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      : <form ref={ref}>
          <Prompt
            when={formState.isTouched && promptIsTouched}
            message='You have unsaved changes are you sure you want to quit?'
          />
          <Box m={1}>
            {React.Children.map(children, child => {
              if (child && child.type === FormElement) {
                return React.cloneElement(child, {
                  value: child.props.value || formState.values[child.props.name],
                  onChange: handleFormChange,
                  error:
                    hasError(child.props.name, formState) ||
                    hasFormValidationError(child.props.name, formErrorsList),
                  helperText: hasError(child.props.name, formState)
                    ? formState.errors[child.props.name][0]
                    : hasFormValidationError(child.props.name, formErrorsList)
                      ? formErrorsList[child.props.name]
                      : null,
                  disabled:
                    (child.props.disabled || submitting) &&
                    formErrorsList.length === 0
                })
              }
              return child
            })}
          </Box>
          <Box m={1} display="flex" justifyContent="flex-end">
            {showCancelButton && (
              <div className={classes.wrapper}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  disableElevation
                  onClick={onCancelButtonClick}
                >
                  {cancelLabel || 'Cancel'}
                </Button>
              </div>
            )}
            {showBackButton && (
              <div className={classes.wrapper}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  disableElevation
                  onClick={onBackButtonClick}
                >
                  { backButtonLabel || 'Back to list' }
                </Button>
              </div>
            )}
            {!readonly && (
              <div className={classes.wrapper}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={
                    (submitting || !formState.isValid) &&
                    formErrorsList.length === 0
                  }
                  size="large"
                  disableElevation
                  color="primary"
                  onClick={event => {
                    event.preventDefault()
                    dispatchClearErrorsForm()
                    onSubmit(formState.values)
                  }}
                >
                  {submitLabel}
                  {Icon && <Icon />}
                </Button>
                {submitting && formErrorsList.length === 0 && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
                {showSuccess && (
                  <Typography className={classes.saved}>Saved!</Typography>
                )}
              </div>
            )}
          </Box>
        </form>
  }
)

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef
})(Form)

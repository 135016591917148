import { APIServices } from './APIServices'
import { addToast, dispatchError } from '../store/errorsToast'
import cookie from 'react-cookies'

export const getAllSessions = params => {
  return dispatch => {
    dispatch({ type: "ALL_SESSIONS_FETCHING" })
    APIServices()
      .getAllSessions(params)
      .then(response => {
        const responseData = response.data
        if (response.status === 200) {
          dispatch({
            type: "GET_ALL_SESSIONS",
            payload: responseData
          })
        } else {
          dispatch(dispatchError(responseData))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.error) {
            dispatch(addToast({ error: error.response.data.error }))
          }
          if (error.response.data.message) {
            dispatch(addToast({ error: error.response.data.message }))
          }
        } else {
          dispatch(addToast({ error: error.message }))
        }
      })
  }
}

export const getSession = itemId => {
  return dispatch => {
    dispatch({ type: "SESSION_FETCHING" })
    APIServices()
      .getSession(itemId)
      .then(response => {
        const responseData = response.data
        if (response.status === 200) {
          dispatch({
            type: "GET_SESSION",
            payload: {
              stage: responseData
            }
          })
        } else {
          dispatch(dispatchError(responseData))
        }
      })
      .catch(error => {
        console.log("error", error)
      })
  }
}

export const createSession = item => {
  return dispatch => {
    dispatch({ type: "SESSION_SUBMIT_FORM_ACTION" })
    APIServices()
      .createSession(item)
      .then(response => {
        const responseData = response.data
        if (response.status === 200) {
          cookie.save('session_id', responseData.id)
          dispatch({
            type: "TOGGLE_SESSION_ADD",
            payload: {
              sessionAdded: responseData.id,
              sessionPosts: responseData.posts,
              sessionCategories: responseData.badges
            }
          })
        } else {
          dispatchError(responseData, dispatch)
        }
      })
      .catch(error => {
        dispatch({ type: "SESSION_SUBMIT_FORM_ACTION" })
        dispatch(dispatchError(error.response))
      })
  }
}

import { APIServices } from './APIServices'
import { addToast, dispatchError } from '../store/errorsToast'

export const getAllStages = params => {
  return dispatch => {
    dispatch({ type: "ALL_STAGES_FETCHING" })
    APIServices()
      .getAllStages(params)
      .then(response => {
        const responseData = response.data
        if (response.status === 200) {
          dispatch({
            type: "GET_ALL_STAGES",
            payload: responseData
          })
        } else {
          dispatch(dispatchError(responseData))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.error) {
            dispatch(addToast({ error: error.response.data.error }))
          }
          if (error.response.data.message) {
            dispatch(addToast({ error: error.response.data.message }))
          }
        } else {
          dispatch(addToast({ error: error.message }))
        }
      })
  }
}

export const getStage = itemId => {
  return dispatch => {
    dispatch({ type: "STAGE_FETCHING" })
    APIServices()
      .getStage(itemId)
      .then(response => {
        const responseData = response.data
        if (response.status === 200) {
          dispatch({
            type: "GET_STAGE",
            payload: {
              stage: responseData
            }
          })
        } else {
          dispatch(dispatchError(responseData))
        }
      })
      .catch(error => {
        console.log("error", error)
      })
  }
}

export const updateStage = (itemId, item) => {
  return dispatch => {
    if (item) {
      dispatch({ type: "STAGE_SUBMIT_FORM_ACTION" })
      APIServices()
        .updateStage(itemId, item)
        .then(response => {
          const responseData = response.data
          if (response.status === 200) {
            dispatch({
              type: "TOGGLE_STAGE_UPDATE",
              payload: {
                showUpdateModal: false
              }
            })
          } else {
            dispatch(dispatchError(responseData))
          }
        })
        .catch(error => {
          dispatch({ type: "STAGE_SUBMIT_FORM_ACTION" })
          dispatch(dispatchError(error.response))
        })
    }
  }
}

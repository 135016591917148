import React, { useEffect, Fragment, useState } from 'react'
import { connect } from 'react-redux'
import FormElement from '../../components/FormElement'
import DialogForm from '../../components/DialogForm'
import { getPost, createPost, updatePost } from '../../api/Post'
import { config } from '../../config'
import { getAllCategories } from '../../api/Category'
import { getAllCharacters } from '../../api/Character'

const mapStateToProps = ({
  post: { fetching: fetchingPost, post, submitting },
  categories: { list: categoriesList },
  characters: { list: charactersList },
}) => {
  return { post, submitting, fetchingPost, categoriesList, charactersList }
}

const mapDispatchToProps = dispatch => ({
  submitCreateItem: item => {
    dispatch(createPost(item))
  },
  submitUpdateItem: (postId, item) => {
    dispatch(updatePost(postId, item))
  },
  dispatchGetPost: postId => {
    dispatch(getPost(postId))
  },
  dispatchGetAllCategories: () => {
    dispatch(getAllCategories({ paginate: 0 }))
  },
  dispatchGetAllCharacters: () => {
    dispatch(getAllCharacters({ paginate: 0 }))
  }
})

const Create = ({
  dispatchGetAllCategories,
  categoriesList,
  dispatchGetAllCharacters,
  charactersList,
  postId,
  open,
  handleClose,
  submitCreateItem,
  submitUpdateItem,
  dispatchGetPost,
  submitting,
  fetchingPost,
  post,
  initialValues
}) => {
  const [uploadedPicture, setUploadedPicture] = useState(null)

  const validationSchema = {
    post: {
      presence: { allowEmpty: false, message: "is required" },
      length: {
        maximum: 300
      }
    },
    confirmationQuestion: {
      presence: { allowEmpty: false, message: "is required" }
    },
    btnTrue: {
      presence: { allowEmpty: false, message: "is required" }
    },
    btnFalse: {
      presence: { allowEmpty: false, message: "is required" }
    },
    correctAnswerText: {
      presence: { allowEmpty: false, message: "is required" }
    },
    wrongAnswerText: {
      presence: { allowEmpty: false, message: "is required" }
    }
  }

  useEffect(() => {
    if (postId) dispatchGetPost(postId)
  }, [])

  useEffect(() => {
    dispatchGetAllCategories()
    dispatchGetAllCharacters()
  }, [])

  const handleFileChange = (file) => {
    setUploadedPicture(file)
  }

  return (
    <Fragment>
      <DialogForm
        open={open}
        handleClose={handleClose}
        title={postId ? "Edit Post" : "Add new post"}
        onSubmit={item => {
          const data = new FormData()
          Object.keys(item)
            .forEach((key) => {
              if (key !== 'postPicture' && key !== 'isActive' && key !== 'isTrue') {
                data.append(key, item[key])
              }
            })
          if (uploadedPicture) {
            data.append('postPicture', uploadedPicture)
          }
          data.append('isTrue', item.isTrue ? 1 : 0)
          data.append('isActive', item.isActive ? 1 : 0)
          if (postId) {
            data.append('_method', 'PUT')
          }
          postId
            ? submitUpdateItem(postId, data)
            : submitCreateItem(data)
        }}
        submitting={submitting}
        submitLabel={postId ? "Update" : "Add"}
        validationSchema={validationSchema}
        fetchingData={postId && fetchingPost}
        initialValues={
          initialValues
            ? initialValues
            : postId && post
            ? {
              characterId: post.characterId,
              categoryId: post.categoryId,
              post: post.post,
              confirmationQuestion: post.confirmationQuestion,
              btnTrue: post.btnTrue,
              btnFalse: post.btnFalse,
              isTrue: post.isTrue,
              correctAnswerText: post.correctAnswerText,
              wrongAnswerText: post.wrongAnswerText,
              isActive: post.isActive
            }
            : {
              post: "",
              confirmationQuestion: "",
              btnTrue: "",
              btnFalse: "",
              isTrue: "",
              correctAnswerText: "",
              wrongAnswerText: "",
              isActive: ""
            }
        }
      >
        <FormElement elementType="autocomplete" name="characterId" label="Character*" items={charactersList} titleField="fullName" />
        <FormElement elementType="autocomplete" name="categoryId" label="Badge*" items={categoriesList} />
        <FormElement
          elementType="text"
          label="Post*"
          name="post"
          multiline
          rows={2}
        />
        <FormElement
          elementType="text"
          label="Confirmation Question*"
          name="confirmationQuestion"
          multiline
          rows={2}
        />
        <FormElement
          elementType="text"
          label="True Button Text*"
          name="btnTrue"
        />
        <FormElement
          elementType="text"
          label="False Button Text*"
          name="btnFalse"
        />
        <FormElement elementType="switch" label="True post" name="isTrue" />
        <FormElement
          elementType="text"
          label="Correct Answer Text*"
          name="correctAnswerText"
          multiline
          rows={3}
        />
        <FormElement
          elementType="text"
          label="Wrong Answer Text*"
          name="wrongAnswerText"
          multiline
          rows={3}
        />
        {post && post.postPicture &&
        <img src={config.FILE_URL + post.postPicture} width="200" />
        }
        <FormElement elementType="file" label="Post Picture" name="postPicture" onFileChange={handleFileChange} />
        <FormElement elementType="switch" label="Active" name="isActive" />
      </DialogForm>
    </Fragment>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Create)

import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const ErrorsToast = () => {
  return (
    <React.Fragment>
      <ToastContainer
        draggablePercent={60}
        autoClose={8000}
        position={toast.POSITION.TOP_RIGHT}
      />
    </React.Fragment>
  )
}

export default ErrorsToast

const local = {
  API_URL: 'https://game.french.test/api/v1',
  FILE_URL: 'https://game.french.test'
}

const production = {
  API_URL: 'https://api.infoxjeu.com/api/v1',
  FILE_URL: 'https://api.infoxjeu.com/'
}
export const config = process.env.REACT_APP_ENV === 'local' ? local : production

const initialState = {
  stage: null,
  fetching: true,
  submitting: false,
  showUpdateModal: false
}

export default function stage (state = initialState, action) {
  switch (action.type) {
    case 'STAGE_FETCHING':
      return {
        ...state,
        fetching: true,
      }
    case 'GET_STAGE':
      return {
        ...state,
        stage: action.payload.stage,
        fetching: false,
        submitting: false,
      }
    case 'STAGE_SUBMIT_FORM_ACTION':
      return {
        ...state,
        submitting: true,
      }
    case 'TOGGLE_STAGE_UPDATE':
      return {
        ...state,
        showUpdateModal: action.payload.showUpdateModal,
        submitting: false,
      }
    case 'ADD_TOAST':
      return {
        ...state,
        fetching: false,
        submitting: false,
      }
    default:
      return state
  }
}

import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={23}
    height={13}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.572 7.514a.81.81 0 0 0 0-1.145l-5.15-5.15a.81.81 0 1 0-1.146 1.144l4.58 4.579-4.58 4.579a.81.81 0 0 0 1.145 1.144l5.151-5.15ZM0 7.751h22V6.132H0v1.62Z"
      fill="#fff"
    />
  </svg>
)

export default SvgComponent

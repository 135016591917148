import moment from 'moment'
import parse from 'html-react-parser'
import React from 'react'

const InstagramPost = ({ selectedCharacter, post }) => {
  return (
    <section className="instagram-post">
      <div className="wrapper">
        <div>
          <div className="post">
            <div className="info">
              <div className="user">
                <div className="profile-pic" style={{ '--character-color': '#' + selectedCharacter.colorHEX }}>
                  <img src={selectedCharacter.characterPicture} alt="" />
                </div>
                <p className="username">{selectedCharacter.username}</p>
              </div>
              <img src="images/instagram/option.PNG" className="options" alt="" />
            </div>
            {post.postPicture &&
              <img src={post.postPicture} className="post-image" alt=""/>
            }
            <div className="post-content">
              <div className="reaction-wrapper">
                <img src="images/instagram/like.PNG" className="icon" alt="" />
                <img src="images/instagram/comment.PNG" className="icon" alt="" />
                <img src="images/instagram/send.PNG" className="icon" alt="" />
                <img src="images/instagram/save.PNG" className="save icon" alt="" />
              </div>
              <p className="likes">{post.likesCount}k Likes</p>
              <p className="description"><span>{selectedCharacter.username}</span> {parse(post.post)}</p>
              <p className="post-time">{moment().format('DD') + ' ' + moment().locale('ar').format('MMM')}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default InstagramPost

import { APIServices } from './APIServices'
import { addToast, dispatchError } from '../store/errorsToast'

export const getAllSessionPosts = params => {
  return dispatch => {
    dispatch({ type: "ALL_SESSION_POSTS_FETCHING" })
    APIServices()
      .getAllSessionPosts(params)
      .then(response => {
        const responseData = response.data
        if (response.status === 200) {
          dispatch({
            type: "GET_ALL_SESSION_POSTS",
            payload: responseData
          })
        } else {
          dispatch(dispatchError(responseData))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.error) {
            dispatch(addToast({ error: error.response.data.error }))
          }
          if (error.response.data.message) {
            dispatch(addToast({ error: error.response.data.message }))
          }
        } else {
          dispatch(addToast({ error: error.message }))
        }
      })
  }
}

export const getSessionPost = itemId => {
  return dispatch => {
    dispatch({ type: "SESSION_POST_FETCHING" })
    APIServices()
      .getSessionPost(itemId)
      .then(response => {
        const responseData = response.data
        if (response.status === 200) {
          dispatch({
            type: "GET_SESSION_POST",
            payload: {
              stage: responseData
            }
          })
        } else {
          dispatch(dispatchError(responseData))
        }
      })
      .catch(error => {
        console.log("error", error)
      })
  }
}

export const createSessionPost = item => {
  return dispatch => {
    dispatch({ type: "SESSION_POST_SUBMIT_FORM_ACTION" })
    APIServices()
      .createSessionPost(item)
      .then(response => {
        const responseData = response.data
        if (response.status === 200) {
          dispatch({
            type: "TOGGLE_SESSION_POST_ADD"
          })
        } else {
          dispatchError(responseData, dispatch)
        }
      })
      .catch(error => {
        dispatch({ type: "SESSION_POST_SUBMIT_FORM_ACTION" })
        dispatch(dispatchError(error.response))
      })
  }
}

import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Copyrights from '../components/Copyrights'
import cookie from 'react-cookies'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { connect } from 'react-redux'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { login } from '../api/Admin'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

const mapStateToProps = ({
  userProfile: { submitting, error }
}) => {
  return {
    submitting,
    error
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchLogin: (email, password) => {
    dispatch(
      login({
        email,
        password
      })
    )
  },
  dispatchResetState: () => {
    dispatch({
      type: 'RESET_ERROR_SUCCESS_STATE'
    })
  }
})

const Login = ({ dispatchLogin, dispatchResetState, submitting, error }) => {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    return () => {
      dispatchResetState()
    }
  }, [])

  const isAuthenticated = cookie.load('access_token')
  if (isAuthenticated) {
    return <Redirect to="/panel/dashboard" />
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <ValidatorForm
          className={classes.form}
          onSubmit={() => dispatchLogin(email, password)}
          instantValidate={false}
        >
          <TextValidator
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            value={email}
            onChange={event => setEmail(event.target.value)}
            autoFocus
            validators={['required', 'isEmail']}
            errorMessages={['Email is required', 'Email is not valid']}
          />
          <TextValidator
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            id="password"
            autoComplete="current-password"
            value={password}
            type={showPassword ? 'text' : 'password'}
            onChange={event => setPassword(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword
                      ? <VisibilityIcon />
                      : <VisibilityOffIcon />
                    }
                  </IconButton>
                </InputAdornment>
              )
            }}
            required
            validators={['required']}
            errorMessages={['Password is required']}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={submitting}
          >
            Login
          </Button>
        </ValidatorForm>
      </div>
      <Box mt={8}>
        <Copyrights />
      </Box>
    </Container>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)

const initialState = {
  list: [],
  totalData: 0,
  fetching: true
}

export default function guests (state = initialState, action) {
  switch (action.type) {
    case "ALL_GUESTS_FETCHING":
      return {
        ...initialState,
        fetching: true
      }
    case "GET_ALL_GUESTS":
      return {
        ...state,
        list: action.payload.data,
        totalData: action.payload.meta.total,
        fetching: false
      }
    case "ADD_TOAST":
      return {
        ...state,
        fetching: false
      }
    default:
      return state
  }
}

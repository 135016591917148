const initialState = {
  user: JSON.parse(localStorage.getItem('user_profile')),
  submitting: false
}

export default function userProfile (state = initialState, action) {
  switch (action.type) {
    case 'USER_PROFILE_REQUEST':
      return {
        ...state,
        submitting: true
      }
    case 'SET_USER_PROFILE':
      return {
        ...state,
        user: action.payload.user,
        submitting: false
      }
    case 'ADD_TOAST':
      return {
        ...state,
        submitting: false
      }
    case 'RESET_ERROR_SUCCESS_STATE':
      return {
        ...state,
        submitting: false
      }
    default:
      return state
  }
}

import { APIServices } from './APIServices'
import { addToast, dispatchError } from '../store/errorsToast'

export const getAllCategories = params => {
  return dispatch => {
    dispatch({ type: "ALL_CATEGORIES_FETCHING" })
    APIServices()
      .getAllCategories(params)
      .then(response => {
        const responseData = response.data
        if (response.status === 200) {
          dispatch({
            type: "GET_ALL_CATEGORIES",
            payload: responseData
          })
        } else {
          dispatch(dispatchError(responseData))
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.error) {
            dispatch(addToast({ error: error.response.data.error }))
          }
          if (error.response.data.message) {
            dispatch(addToast({ error: error.response.data.message }))
          }
        } else {
          dispatch(addToast({ error: error.message }))
        }
      })
  }
}

export const getCategory = itemId => {
  return dispatch => {
    dispatch({ type: "CATEGORY_FETCHING" })
    APIServices()
      .getCategory(itemId)
      .then(response => {
        const responseData = response.data
        if (response.status === 200) {
          dispatch({
            type: "GET_CATEGORY",
            payload: {
              category: responseData
            }
          })
        } else {
          dispatch(dispatchError(responseData))
        }
      })
      .catch(error => {
        console.log("error", error)
      })
  }
}

export const updateCategory = (itemId, item) => {
  return dispatch => {
    if (item) {
      dispatch({ type: "CATEGORY_SUBMIT_FORM_ACTION" })
      APIServices()
        .updateCategory(itemId, item)
        .then(response => {
          const responseData = response.data
          if (response.status === 200) {
            dispatch({
              type: "TOGGLE_CATEGORY_UPDATE",
              payload: {
                showUpdateModal: false
              }
            })
          } else {
            dispatch(dispatchError(responseData))
          }
        })
        .catch(error => {
          dispatch({ type: "CATEGORY_SUBMIT_FORM_ACTION" })
          dispatch(dispatchError(error.response))
        })
    }
  }
}
